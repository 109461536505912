<template>
	<m-intro
		img="ExploreIntro.svg"
		:headline="$t('explore.intro.headline')"
		:button="$t('explore.intro.button')"
		:handleButtonClick="handleButton"
	>
		<p-text :text="readableDiveCount + $t('explore.intro.copy')" />
	</m-intro>
</template>

<script>
	export default {
		name: 'p-explore-intro',
		data() {
			return {
				readableDiveCount: ''
			};
		},
		computed: {
			diveCount() { return this.$store.state.app.diveCount; },
		},
		mounted() {
			this.setReadableDiveCount(this.diveCount);
		},
		methods: {
			setReadableDiveCount(diveCount) {
				const words = ['Null', 'Eins', 'Zwei', 'Drei', 'Vier', 'Fünf', 'Sechs', 'Sieben', 'Acht', 'Neun'];
				this.readableDiveCount = words[diveCount];
			},
			handleButton() {
				this.$store.commit('explore/SET_SHOW_INTRO', false);
			},
		}
	};
</script>
