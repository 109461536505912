<template>
	<div :class="'p-text-wrapper' + marginClass">
		<p
			:class="'p-text'"
		>
			<span v-html="text" />
			<slot />
			<p-text-link v-if="linkText" :text="linkText" :link="linkHref" />
		</p>
	</div>
</template>

<script>
	export default {
		name: 'p-text',
		props: {
			text: {
				type: String,
				required: false,
			},
			margin: {
				type: String,
				required: false,
			},
			linkText: {
				type: String,
				required: false
			},
			linkHref: {
				type: String,
				required: false
			}
		},
		computed: {
			marginClass() { return this.margin === 's' ? ' p-text-wrapper--margin-s' : ''; },
		}
	};
</script>
