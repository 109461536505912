<template>
	<ul class="p-tab-nav">
		<li :class="'p-tab-nav__item' + getNavClass(0)" v-on:click="handleNavClick(0)">
			{{$t('dive.tabNav.szenario')}}
		</li>
		<li :class="'p-tab-nav__item' + getNavClass(1)" v-on:click="handleNavClick(1)">
			{{$t('dive.tabNav.example')}}
		</li>
		<li :class="'p-tab-nav__item' + getNavClass(2)" v-on:click="handleNavClick(2)">
			{{$t('dive.tabNav.consideration')}}
		</li>
		<li :class="'p-tab-nav__item' + getNavClass(3)" v-on:click="handleNavClick(3)">
			{{$t('dive.tabNav.action')}}
		</li>
	</ul>
</template>

<script>
	export default {
		name: 'p-tab-nav-dive',
		computed : {
			activeDiveStep() { return this.$store.state.dive.activeDiveStep; },
			unlockedDiveStep() { return this.$store.state.dive.unlockedDiveStep; },
			isDive() { return this.$store.state.app.activeLevel === 1; } // replace with final nav logic
		},
		methods : {
			async handleNavClick(step) {

				// item is only clickable if it was unlocked before
				if(this.isDive && step > this.unlockedDiveStep) return;

				await this.$store.commit('dive/SET_ACTIVEDIVESTEP' , {activeDiveStep : step});
			},
			getNavClass(number) {
				// During a Dive not all items are clickable
				if(this.isDive) {
					if(this.activeDiveStep === number) {
						return ' p-tab-nav__item--active';
					}
					else if(number > this.unlockedDiveStep) {

						return ' p-tab-nav__item--disabled';
					} else {
						return '';
					}

				// Outside a Dive everything is clickable
				} else {

					if(this.activeDiveStep === number) {
						return ' p-tab-nav__item--active';
					} else {
						return '';
					}
				}
			},
		}
	};
</script>
