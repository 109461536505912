<template>
	<div
		class="m-slideshow"
	>
		<p-tagline v-if="title" :text="title" class="m-swiper__tagline-title"></p-tagline>

		<swiper
			:enabled="enabled"
			:auto-update="true"
			:auto-destroy="true"
			:delete-instance-on-destroy="true"
			:cleanup-styles-on-destroy="true"

			:options="swiperOptions"
		>
			<slot name="slider-items"></slot>

		</swiper>

		<template v-if="showSliderControls">

			<div class="m-swiper-slider-controls">
				<div  
					slot="button-prev" 
					class="swiper-button-prev m-slider__arrow  m-slider__arrow--prev"
				>
					<svg viewBox="0 0 14 24" class="m-slider__arrow-icon">
						<path d="M0.812988 12.0012L12.1267 0.6875L13.5409 2.10171L2.2272 13.4154L0.812988 12.0012Z" fill="#1D1DFF"/>
						<path d="M0.812988 12.0012L12.1267 23.3149L13.5409 21.9007L2.2272 10.587L0.812988 12.0012Z" fill="#1D1DFF"/>
					</svg>
				</div>

				<div 
					class="swiper-pagination" 
					slot="pagination"
				>
				</div>

				<div 
					slot="button-next" 
					class="swiper-button-next m-slider__arrow  m-slider__arrow--next">
					<svg viewBox="0 0 14 24" class="m-slider__arrow-icon">
						<path d="M13.187 12.0012L1.8733 0.6875L0.45909 2.10171L11.7728 13.4154L13.187 12.0012Z" fill="#1D1DFF"/>
						<path d="M13.187 12.0012L1.8733 23.3149L0.45909 21.9007L11.7728 10.587L13.187 12.0012Z" fill="#1D1DFF"/>
					</svg>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	/**
	 * Renders a slider which contains cards.
	 * 
	 * take a look at the doc here: https://github.surmon.me/vue-awesome-swiper/
	 * 
	 * @displayname Vote Slider
	 *
	 *
	 *
	 * @example
	 * // prepare items array
	 * let items = [
	 * 	{
	 * 		id: '123',
	 * 		text: 'This is a text.',
	 *  	category: 'correct'
	 * 	}
	 * ];
	 *
	 * // add headline to items
	 * this.items.unshift({text: this.$i18n.t('dive.voteHeadlines.vote1')});
	 *
	 */

	import { Swiper } from 'vue-awesome-swiper';
	import 'swiper/css/swiper.css';
	export default {
		name: 'm-swiper',
		components: {
			'swiper': Swiper
		},
		props: {
			showSliderControls: {
				type: Boolean,
				required: false, 
				default: true
			},
			enabled: {
				type: Boolean,
				required: false,
				default: true
			},
			title: {
				type: String,
				required: false,
				default: null
			}
		},
		data() {
			return {
				swiperOptions: {
					loop: false,
					slidesPerView: 'auto',
					freeMode: false,
					mousewheel: false,
					grabCursor: true,
					pagination: {
						el: '.swiper-pagination',
						type: 'progressbar'
					},	
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					breakpoints: {
						1024: {
							spaceBetween: 40
						},
						320: {
							spaceBetween: 30
						}
					}
				},
			};
		},
		beforeMount() {
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
		},
		beforeUpdate() {
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
		},
		beforeDestroy() {
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', false);
		}
	};
</script>
