<template>
	<form
		class="m-form"
		v-on:submit.prevent="handleWrapper"
	>
		<slot />
	</form>
</template>

<script>
	export default {
		name: 'm-form',
		props: {
			handle: {
				type: Function,
				required: true,
			},
		},
		methods: {
			handleWrapper() {
				this.handle();
			},
		},
	};
</script>
