<template>
	<a
		:class="'p-button' + colorClass + disabledClass"
		:href="link"
		:target="newTab ? '_blank' : null"
	>
		<span v-if="$slots.default" class="p-button__icon">
			<slot />
		</span>

		{{ text }}

	</a>
</template>

<script>
	export default {
		name: 'p-button',
		props: {
			text: {
				type: String,
				required: true,
			},
			link: {
				type: String,
				required: true,
			},
			newTab: {
				type: Boolean,
				required: false,
				default: false,
			},
			color: {
				type: String,
				required: false,
				default: 'primary',
			},
			icon: {
				type: String,
				required: false,
			},
		},
		computed: {
			colorClass() { return ' p-button--' + this.color; },
			disabledClass() { return this.disabled ? ' p-button--disabled' : ''; }
		},
	};
</script>
