import Vue from 'vue';
import Vuex from 'vuex';

import app from './app';
import appointment from './appointment';
import brand from './brand';
import dive from './dive';
import explore from './explore';
import firstTime from './firstTime';
import intro from './intro';
import slider from './slider';
import user from './user';
import userMenu from './userMenu';
import vote from './vote';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: false,
	modules: {
		app: app,
		appointment: appointment,
		brand: brand,
		dive: dive,
		explore: explore,
		firstTime: firstTime,
		intro: intro,
		slider: slider,
		user: user,
		userMenu: userMenu,
		vote: vote,
	}
});
