export default {
	namespaced: true,
	state: {
		step: 0
	},
	mutations: {
		SET_STEP(state, payload) {
			state.step = payload.step;
		},
	},
	actions: {
		// TODO:  fetch persistent data from API
		SET_STEP(state, payload) {
			state.step = payload.step;
		},
	}
};
