import VueScrollTo from 'vue-scrollto';
import VueAwesomeSwiper from 'vue-awesome-swiper';

import PHeadline from '@/components/pattern/text/p-headline';
import PTagline from '@/components/pattern/text/p-tagline';
import PText from '@/components/pattern/text/p-text';

import PButton from '@/components/pattern/form/p-button';
import PButtonLink from '@/components/pattern/form/p-button-link';
import PTextLink from '@/components/pattern/form/p-text-link';
import PInput from '@/components/pattern/form/p-input';
import PRadio from '@/components/pattern/form/p-radio';

import PCard from '@/components/pattern/layout/p-card';
import PLoading from '@/components/pattern/layout/p-loading';
import PSpinner from '@/components/pattern/layout/p-spinner';
import PStepDisplay from '@/components/pattern/layout/p-step-display';
import PMobileMenue from '@/components/pattern/layout/p-mobile-menue';
import PSlider from '@/components/modules/layout/p-slider.vue';
import PUserMenu from '@/components/pattern/layout/p-user-menu.vue';
import PTimer from '@/components/pattern/layout/p-timer';

import IArrowRight from '@/components/pattern/layout/icons/i-arrow-right';

import MModSpacer from '@/components/modules/layout/m-mod-spacer';

import MAwardBadge from '@/components/modules/layout/m-award-badge';
import MAnswerCard from '@/components/modules/layout/m-answer-card';
import MCountdown from '@/components/modules/layout/m-countdown';
import MDevNav from '@/components/modules/layout/m-dev-nav';
import MButtonGroup from '@/components/modules/form/m-button-group';
import MForm from '@/components/modules/form/m-form';
import MLoadingSlider from '@/components/modules/layout/m-loading-slider';
import MModal from '@/components/modules/layout/m-modal';
import MTextTwoCol from '@/components/modules/layout/m-text-two-col';
import MTextInput from '@/components/modules/layout/m-text-input';
import MIntro from '@/components/modules/layout/m-intro';
import MHeader from '@/components/modules/layout/m-header';
import MGrid from '@/components/modules/layout/m-grid';
import MFraction from '@/components/modules/layout/m-fraction';
import MSwiper from '@/components/modules/layout/m-swiper';

// intro
import pDiveIntro from '@/components/pages/intro/p-dive-intro.vue';

// dive
import PTabNavDive from '@/components/pattern/layout/p-tab-nav-dive.vue';
import PDiveIntroFTU from '@/components/pages/dive/includes/p-dive-intro-ftu.vue';
import PDiveIntroWelcomeBack from '@/components/pages/dive/includes/p-dive-intro-welcome-back.vue';
import PDiveIntroComplete from '@/components/pages/dive/includes/p-dive-intro-complete.vue';
import PAction from '@/components/pages/dive/includes/p-action.vue';
import PConsideration from '@/components/pages/dive/includes/p-consideration.vue';
import PExample from '@/components/pages/dive/includes/p-example.vue';
import PSzenario from '@/components/pages/dive/includes/p-szenario.vue';
import PDiveExplore from '@/components/pages/dive/includes/p-dive-explore.vue';

// vote
import PVote from '@/components/pages/vote/p-vote';
import MVoteCard from '@/components/modules/layout/m-vote-card';
import MHeadlineCard from '@/components/modules/layout/m-headline-card';

// explore
import PExplore from '@/components/pages/explore/p-explore';
import PDiveStepper from '@/components/pattern/layout/p-dive-stepper.vue';
import PTabNavExplore from '@/components/pattern/layout/p-tab-nav-explore.vue';
import PExploreIntro from '@/components/pages/explore/includes/p-explore-intro';
import PExploreDive from '@/components/pages/explore/includes/p-explore-dive.vue';

const initComponents = (Vue) => {
	Vue.use(VueScrollTo, {
		container: '#vue-scroll-container',
		duration: 500,
		easing: 'ease',
		offset: -80,
		force: true,
		cancelable: true,
		onStart: false,
		onDone: false,
		onCancel: false,
		x: false,
		y: true
	});

	Vue.use(VueAwesomeSwiper);

	Vue.component('p-loading', PLoading);
	Vue.component('p-headline', PHeadline);
	Vue.component('p-text', PText);
	Vue.component('p-tagline', PTagline);

	Vue.component('p-button', PButton);
	Vue.component('p-button-link', PButtonLink);
	Vue.component('p-card', PCard);
	Vue.component('p-text-link', PTextLink);
	Vue.component('p-input', PInput);
	Vue.component('p-radio', PRadio);

	Vue.component('p-spinner', PSpinner);
	Vue.component('p-step-display', PStepDisplay);

	Vue.component('p-slider', PSlider);
	Vue.component('p-user-menu', PUserMenu);
	Vue.component('p-timer', PTimer);
	Vue.component('p-user-menu', PUserMenu);
	Vue.component('p-mobile-menue', PMobileMenue);

	Vue.component('i-arrow-right', IArrowRight);

	Vue.component('m-mod-spacer', MModSpacer);
	Vue.component('m-fraction', MFraction);

	Vue.component('m-award-badge', MAwardBadge);
	Vue.component('m-answer-card', MAnswerCard);
	Vue.component('m-countdown', MCountdown);
	Vue.component('m-dev-nav', MDevNav);
	Vue.component('m-button-group', MButtonGroup);
	Vue.component('m-form', MForm);
	Vue.component('m-loading-slider', MLoadingSlider);
	Vue.component('m-modal', MModal);
	Vue.component('m-text-two-col', MTextTwoCol);
	Vue.component('m-text-input', MTextInput);
	Vue.component('m-intro', MIntro);
	Vue.component('m-header', MHeader);
	Vue.component('m-grid', MGrid);
	Vue.component('m-swiper', MSwiper);

	// intro
	Vue.component('p-dive-intro', pDiveIntro);

	// Vote
	Vue.component('p-vote', PVote);
	Vue.component('m-vote-card', MVoteCard);
	Vue.component('m-headline-card', MHeadlineCard);

	// Dive
	Vue.component('p-tab-nav-dive', PTabNavDive);
	Vue.component('p-dive-intro-ftu', PDiveIntroFTU);
	Vue.component('p-dive-intro-complete', PDiveIntroComplete);
	Vue.component('p-dive-intro-welcome-back', PDiveIntroWelcomeBack);
	Vue.component('p-action', PAction);
	Vue.component('p-consideration', PConsideration);
	Vue.component('p-example', PExample);
	Vue.component('p-szenario', PSzenario);
	Vue.component('p-dive-explore', PDiveExplore);
	
	// explore
	Vue.component('p-explore', PExplore);
	Vue.component('p-dive-stepper', PDiveStepper);
	Vue.component('p-tab-nav-explore', PTabNavExplore);
	Vue.component('p-explore-intro', PExploreIntro);
	Vue.component('p-explore-dive', PExploreDive);
};
export default initComponents;
