<template>
	<div class="p-spinner">
		<div class="p-spinner__part top">
			<div class="p-spinner__rotator" />
		</div>
		<div class="p-spinner__part bottom">
			<div class="p-spinner__rotator" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'p-spinner',
	};
</script>
