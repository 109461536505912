<template>
	<div :class="'m-grid'">
		<div class="grid-container">
			<div v-if="!hasSlot" :class="'column-12'">
				<slot />
			</div>
			<slot v-else />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'm-grid',
		props: {
			hasSlot: {
				type: Boolean,
				required: false,
				default: false,
			},
		}
	};
</script>
