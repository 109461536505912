import { DiveStepsEnum } from '../plugins/enums';

export default {
	namespaced: true,
	state: {
		// current dive is located in user store
		/**
		 * all possible divesteps are:
		 * -1 --> Intro (will only be visible one time)
		 * 0 --> szenario
		 * 1 --> example
		 * 2 --> consideration
		 * 3 --> action
		 * 4 --> vote
		 * 5 --> isAnonymous
		 * 6 --> explore
		 */
		activeDiveStep: -1, // init: -1
		unlockedDiveStep: 0,
		buttonSubmitted: false,
		disabledButton: true,
		answer: null,
		diveIndex: 0,
		isPlaying: false,
		showModal: null,
		diveContent: {},
	},
	mutations: {
		SET_IS_PLAYING(state, payload) {
			state.isPlaying  = payload.isPlaying;
		},
		SET_ACTIVEDIVESTEP(state, payload) {
			state.activeDiveStep  = payload.activeDiveStep;
		},
		SET_UNLOCKED_DIVESTEP(state, payload) {
			state.unlockedDiveStep = payload.unlockedDiveStep;
		},
		SET_BUTTONSUBMITTED(state, payload) {
			state.buttonSubmitted = payload.buttonSubmitted;
		},
		SET_BUTTON_STATE(state, payload) {
			state.disabledButton = payload.disabledButton;
		},
		SET_SHOW_MODAL(state, payload) {
			state.showModal = payload.showModal;
		},
		SET_ANSWER(state, payload) {
			state.answer = payload.answer;
		},
		SET_DIVECONTENT(state, payload) {
			state.diveContent = payload.diveContent;
		},
		SET_DIVE_INDEX(state, payload) {
			state.diveIndex = payload.diveIndex;
		},
		START_NEW_DIVE(state) { // TODO: move to actions and check if it was the last dive....
			state.activeDiveStep = DiveStepsEnum.SZENARIO;
			state.unlockedDiveStep = 0;
			state.answer = null;
			state.diveIndex += 1;
		}
	},
	actions: {
		// TODO:  fetch persistent data from API
		SET_BASIC_DIVE_DATA({ state }, payload) {
			state.answer = payload.answer;
			state.diveIndex = payload.diveIndex;
			state.dives = payload.dives;
		},
		SET_DIVE_DATA({state}, payload) {
			state.diveContent = payload.dives;
		},
		START_NEXT_DIVE_STEP({state, commit, rootState}) {

			switch (state.activeDiveStep) {
				case DiveStepsEnum.VOTE:
					console.log('...prepare dive explore');
					commit('vote/RESET_VOTES', null, { root: true} );
					commit('SET_BUTTON_STATE', { disabledButton: true });
					commit('SET_ACTIVEDIVESTEP', {activeDiveStep: DiveStepsEnum.EXPLORE});

					if(state.activeDiveStep === DiveStepsEnum.ACTION) { // TODO
						commit('SET_IS_PLAYING' , {isPlaying : false});
						commit('START_NEW_DIVE');
					}
					break;

				case DiveStepsEnum.EXPLORE:
					if (state.diveIndex + 1 > rootState.app.diveCount) {
						commit('SET_IS_PLAYING' , {isPlaying : false});
					}

					commit('START_NEW_DIVE');

					break;

				default:
					console.log('...try increase dive step');
					break;
			}
		}
	},
	getters: {
		dive: (state) => {
			return {
				answer: state.answer,
				diveIndex: state.diveIndex
			};
		}
	}
};
