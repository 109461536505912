<template>
	<p-dive-intro
		img="DiveIntro.svg"
		:headline="$t('dive.introWelcomeBack.headline')"
		:button="$t('dive.introWelcomeBack.button')"
		:buttonClick="handleButton"
	>
		<p-text>
			{{$t('dive.introWelcomeBack.copy1')}} <span class="p-text--italic p-text--bold">{{ currentDive }}/{{ diveCount }}</span>.
		</p-text>
	</p-dive-intro>
</template>

<script>
	export default {
		name: 'p-dive-intro-welcome-back',
		data() {
			return {
				employees: 'Congstars',
				readableDiveCount: ''
			};
		},
		computed: {
			activePlayers() { return this.getRandomNumber(51, 200);},
			currentDive() { return this.$store.state.user.currentDive; },
			diveCount() { return this.$store.state.app.diveCount; },
		},
		mounted() {
		},
		methods: {
			getRandomNumber(min, max) {
				return Math.floor(Math.random() * (max - min + 1) ) + min;
			},
			handleButton() {
				// unlock and start dive
				this.$store.commit('dive/SET_IS_PLAYING' , {isPlaying : true});
				this.$store.commit('dive/SET_ACTIVEDIVESTEP' , {activeDiveStep : 0});
			}
		}
	};
</script>
