<template>
	<div class="p-loading-wrapper">
		<i class="p-loading__indicator" :class="isTransparent ? 'p-loading__indicator--transparent' : null"></i>
	</div>
</template>

<script>
	export default {
		name: 'p-loading',
		props: {
			isTransparent: {
				type: Boolean,
				required: false,
				default: false
			}
		}
	};
</script>
