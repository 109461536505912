const DiveStepsEnum = Object.freeze({
	INTRO: -1,
	SZENARIO: 0,
	EXAMPLE: 1,
	CONSIDERATION: 2,
	ACTION: 3,
	VOTE: 4,
	EXPLORE: 5,
	IS_ANONYMOUS: 6
});

export default DiveStepsEnum;