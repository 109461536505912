import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store';
import VueI18n from 'vue-i18n';
import messages from './lang';

import Utils from './plugins/utils';
import Api from './plugins/api';

import initComponents from './plugins/globalComponents';
import router from './router';


// Axios
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_REST_API_URL;
//Vue.axios.defaults.headers.common['Authorization'] = `Basic ${token}`;
//Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

if(process.env.NODE_ENV === 'development') {

	Vue.axios.interceptors.request.use(req => {
		//console.log('%c' + req.method.toUpperCase() + ': %c' + req.url, 'color: red', 'color: #ff7e21');
		if(req.data) {
			//console.log('%c Request:', 'color: #00bbdc');
			//console.group(req.data);
			//console.groupEnd();
		}
		return req;
	});

	Vue.axios.interceptors.response.use((res) => {
		if(res.data) {
			//console.log('%c Response:', 'color: #00bbdc');
			//console.group(res.data);
			//console.groupEnd();
			//console.log('');
		}
		return res;
	});

}

// i18n
Vue.use(VueI18n);
export const i18n = new VueI18n({
	locale: 'de',
	fallbackLocale: 'de',
	messages
});

// Plugins
Vue.use(Utils);
Vue.use(Api, i18n);

// Global components
initComponents(Vue);

Vue.config.productionTip = false;

// create Event Bus
export const eventBus = new Vue();

new Vue({
	store: store,
	i18n: i18n,
	router,
	render: h => h(App)
}).$mount('#app');
