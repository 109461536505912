<template>
	<div class="p-consideration">
		<m-form
			:handle="handleForm"
		>
			<m-text-two-col
				:headline="headline"
				:button="button"
				:copy="copy"
				:copySecond="additionalText"
				:img="image"
			/>
		</m-form>
	</div>
</template>

<script>
	export default {
		name: 'p-consideration',
		props: {
			diveIndex: {
				type: String,
				required: true,
			}
		},
		computed: {
			headline() { return this.$store.state.dive.diveContent[this.diveIndex].consideration.title; },
			copy() { return this.$store.state.dive.diveContent[this.diveIndex].consideration.text; },
			button() { return this.$store.state.dive.diveContent[this.diveIndex].consideration.buttonText || 'weiter'; },
			image() { return this.$store.state.dive.diveContent[this.diveIndex].consideration.image; },
			additionalText() { return this.$store.state.dive.diveContent[this.diveIndex].consideration.additionalText; },
		},
		created() {
			this.$scrollPageToTop();
		},
		methods: {
			async handleForm() {
				this.$store.commit('dive/SET_ACTIVEDIVESTEP', { activeDiveStep: 3 }, { root: true });
				this.$store.commit('dive/SET_UNLOCKED_DIVESTEP', { unlockedDiveStep: 3 }, { root: true });
			}
		}
	};
</script>
