<template>
	<div :class="'p-headline-wrapper'">
		<h1
			v-if="h1"
			:class="'p-headline p-headline--h1' + borderClass + overflowClass + marginTopClass"
		>
			<span v-html="text" />
		</h1>
		<h2
			v-if="h2"
			:class="'p-headline p-headline--h2' + borderClass + overflowClass + marginTopClass"
		>
			<span v-html="text" />
		</h2>
		<h3 v-if="h3"
			class="p-headline p-headline--h3"
		>
			<span v-html="text" />
		</h3>
		<h4 v-if="h4"
			class="p-headline p-headline--h4"
		>
			<span v-html="text" />
		</h4>
		<h5 v-if="h5"
			class="p-headline p-headline--h5"
		>
			<span v-html="text" />
		</h5>
	</div>
</template>

<script>
	export default {
		name: 'p-headline',
		props: {
			text: {
				type: String,
				required: true,
			},
			border: {
				type: Boolean,
				required: false,
				default: false,
			},
			hasMarginTop: {
				type: Boolean,
				required: false,
				default: true,
			},
			noOverflow: {
				type: Boolean,
				required: false,
				default: false,
			},
			h1: {
				type: Boolean,
				required: false,
				default: false,
			},
			h2: {
				type: Boolean,
				required: false,
				default: false,
			},
			h3: {
				type: Boolean,
				required: false,
				default: false,
			},
			h4: {
				type: Boolean,
				required: false,
				default: false,
			},
			h5: {
				type: Boolean,
				required: false,
				default: false,
			}
		},
		computed: {
			borderClass() { return this.border ? ' p-headline--border' : ''; },
			overflowClass() { return this.noOverflow ? ' p-headline--no-overflow' : ''; },
			marginTopClass() { return this.hasMarginTop ? ' p-headline--margin-top' : ''; }
		},
	};
</script>
