<template>
	<div class="p-dive-stepper">
		<button class="p-dive-stepper__button" v-on:click="() => handleNavClick(-1)">
			<svg viewBox="0 0 9 16" :class="getFillClass(-1)">
				<path d="M-6.39385e-07 7.99926L7.31371 0.685548L8.72792 2.09976L1.41421 9.41347L-6.39385e-07 7.99926Z" fill="#AAAAAA"/>
				<path d="M-6.39385e-07 7.99926L7.31371 15.313L8.72792 13.8988L1.41421 6.58504L-6.39385e-07 7.99926Z" fill="#AAAAAA"/>
			</svg>
		</button>
		{{activeTab}}/{{diveCount}}
		<button class="p-dive-stepper__button" v-on:click="() => handleNavClick(1)">
			<svg viewBox="0 0 9 15" :class="getFillClass(1)">
				<path d="M8.72852 7.31371L1.41481 6.39385e-07L0.000594263 1.41421L7.3143 8.72792L8.72852 7.31371Z" fill="#1D1DFF"/>
				<path d="M8.72852 7.31371L1.41481 14.6274L0.000595294 13.2132L7.3143 5.8995L8.72852 7.31371Z" fill="#1D1DFF"/>
			</svg>
		</button>
	</div>
</template>

<script>
	import { eventBus } from '@/main';
	export default {
		name: 'p-dive-stepper',
		computed : {
			activeTab() { return this.$store.state.explore.activeTab; },
			diveCount() { return this.$store.state.app.diveCount; }
		},
		methods : {
			getFillClass(counter) {
				if(this.activeTab + counter > this.diveCount || this.activeTab + counter < 1) {
					return 'p-dive-stepper__arrow p-dive-stepper__arrow--disabled';
				} else {
					return 'p-dive-stepper__arrow';
				}
			},
			async handleNavClick(counter) {

				const diveToShow = this.activeTab + counter;
				if(diveToShow > this.diveCount || diveToShow < 1) return;

				await this.$store.commit('explore/SET_ACTIVE_TAB' , diveToShow);
				/**
				 * the emitted 'changeExploreDive' will call a function in 'explore-dive' component
				 */
				eventBus.$emit('changeExploreDive', diveToShow);
			},
		}
	};
</script>
