<template>
	<div class="p-szenario">
		<m-form
			:handle="handleForm"
		>
			<m-text-two-col
				:headline="headline"
				:copy="copy"
				:button="button"
				:img="image"
				:copySecond="additionalText"
			/>
		</m-form>
	</div>
</template>

<script>
	export default {
		name: 'p-szenario',
		props: {
			diveIndex: {
				type: Number,
				required: true,
			}
		},
		computed: {
			headline() { return this.$store.state.dive.diveContent[this.diveIndex].scenario.title; },
			copy() { return this.$store.state.dive.diveContent[this.diveIndex].scenario.text; },
			button() { return this.$store.state.dive.diveContent[this.diveIndex].scenario.buttonText; },
			image() { return this.$store.state.dive.diveContent[this.diveIndex].scenario.image; },
			additionalText() { return this.$store.state.dive.diveContent[this.diveIndex].scenario.additionalText; }
		},
		created() {
			this.$scrollPageToTop();
		},
		methods: {
			async handleForm() {
				this.$store.commit('dive/SET_ACTIVEDIVESTEP', { activeDiveStep: 1 }, { root: true });
				this.$store.commit('dive/SET_UNLOCKED_DIVESTEP', { unlockedDiveStep: 1 }, { root: true });
			}
		}
	};
</script>
