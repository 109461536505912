<template>
	<svg
		class="i-icon"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 9 18"
		width="9px"
		height="18px"
	>
		<polygon class="bg-fill" fill="#ffffff" points="9 9 0 0 0 18 9 9" />
	</svg>
</template>

<script>
	export default {
		name: 'i-arrow-right',
	};
</script>
