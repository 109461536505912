<template>
	<div :class="'p-input ' + (counter ? ' p-input--growing' : '')">
		<span
			v-if="counter"
			class="p-input__counter"> {{maxLength}}</span>
		<span
			v-if="counter"
			class="p-input__progress"
		></span>

		<textarea
			v-if="textarea"
			:id="id"
			:value="value"
			:placeholder="placeholder"
			:class="'p-input__field p-input__textarea'"
			:disabled="disabled"
			:ref="'input' + name"
			:maxlength="maxLength ? maxLength : null"
			v-on:input="updateInput($event.target)"
			v-on:keyup.enter="keyUpCallback ? keyUpCallback() : null"
		/>
		<input
			v-else
			:id="id"
			:value="value"
			:placeholder="placeholder"
			v-bind:class="{ 'p-input__field': true, 'error': !!errorMessage }"
			:disabled="disabled"
			:type="!!type ? type : 'text'"
			:ref="'input' + name"
			:maxLength="maxLength ? maxLength : null"
			:minLength="minLength ? minLength : null"
			:size="size ? size : null"
			:pattern="pattern ? pattern : null"
			v-on:input="updateInput($event.target)"
			v-on:keyup.enter="keyUpCallback ? keyUpCallback() : null"
		/>
		<p class="errorText" v-if="!!errorMessage">{{this.errorMessage.toUpperCase()}}</p>
	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'p-input',
		props: {
			label: {
				type: String,
				required: false,
			},
			name: {
				type: String,
				required: false,
			},
			placeholder: {
				type: String,
				required: false,
			},
			value: {
				type: [String, Number],
				required: false,
			},
			disabled: {
				type: Boolean,
				required: false,
				default: false,
			},
			autofocus: {
				type: Boolean,
				required: false,
				default: false,
			},
			textarea: {
				type: Boolean,
				required: false,
				default: false,
			},
			type: {
				type: String,
				required: false,
				default: 'text',
				validator: (val) => {
					return (
						['url', 'text', 'password', 'email', 'number', 'password'].indexOf(val) !== -1
					);
				}
			},
			maxLength: {
				type: Number,
				required: false,
			},
			minLength: {
				type: Number,
				required: false,
			},
			size: {
				type: Number,
				required: false,
			},
			pattern: {
				type: String,
				required: false,
			},
			keyUpCallback: {
				type: Function,
				required: false,
			},
			counter: {
				type: Boolean,
				required: false,
				default: false,
			},
			errorMessage: {
				type: String,
				required: false,
				default: null,
			}
		},
		data() {
			return {
				id: uuidv4(),
			};
		},
		mounted() {
			if(this.autofocus) {
				const inputRef = 'input' + this.name;
				this.$refs[inputRef].focus();
			}
		},
		methods: {
			updateInput(element) {
				this.$emit('inputUpdate', element);
				if(this.counter) {

					element.addEventListener('keydown', function() {
						const el = this;
						el.style.cssText = 'height:' + el.scrollHeight + 'px';
					});

					let len = element.value.length; // value length of textarea
					let maxOfLen = this.maxLength - len; // calculate max length of characters by typing
					let widthOfProgressbar = (100 / this.maxLength) * len; // calculate with of progress bar

					// insert counter by last 20 characters

					element.closest('.p-input').querySelector('.p-input__counter').innerText = maxOfLen;

					//console.log(document.querySelectorAll('.p-input__counter'), 'counter');

					if (maxOfLen <= 0) {
						element.closest('.p-input').classList.add('p-input--growing-filled');
					} else {
						element.closest('.p-input').classList.remove('p-input--growing-filled');
					}

					element.closest('.p-input').querySelector('.p-input__progress').style.width = widthOfProgressbar + '%'; // inster with of progress bar
				}

				if(this.keyUpCallback) {
					this.keyUpCallback();
				}
			}
		}
	};
</script>
