<template>
	<ul class="p-tab-nav">
		<li :class="'p-tab-nav__item' + getActiveClass(1)">
			<span v-on:click="handleNavClick(1)">{{$t('explore.tabNavTitle')}} </span>
			<p-dive-stepper/>
		</li>
	</ul>
	
</template>

<script>
	export default {
		name: 'p-tab-nav-explore',
		computed : {
			activeTab() { return this.$store.state.explore.activeTab; }
		},
		methods : {
			async handleNavClick(activeTab) {
				// no action if dive tab is active and user wants to click on 'DIVE' tab
				if (activeTab === 1 && this.activeTab > 0) return;

				await this.$store.commit('explore/SET_ACTIVE_TAB' , activeTab);
			},
			// returns active class if tabindex is equal to index in store
			getActiveClass(index) {
				if(
					index === 0 && this.activeTab === 0
					|| 
					index === 1 && this.activeTab > 0
				) return ' p-tab-nav__item--active';
				return '';
			}
		}
	};
</script>
