import store from '../store';
import axios from 'axios';

const Api = {
	install(Vue) {
		/**
		 * requestBody: {
		 *     auth_token: String
		 * 		client_id: String
		 * }
		 * auth_token: get token from URL and set into locale storage
		 */
		Vue.prototype.$registerForAuthToken = async (email, languageKey) => {
			Vue.axios.defaults.headers.common['Accept-Language'] = languageKey;

			const requestBody = {
				email: email
			};

			return await axios.post('app/send-authtoken', requestBody);
		};
		/**
		 * requestBody: {
		 *     auth_token: String
		 * 		client_id: String
		 * }
		 * auth_token: get token from URL and set into locale storage
		 */
		Vue.prototype.$getAuthToken = async (token, clientId) => {

			const requestBody = {
				auth_token: token,
				client_id: clientId
			};

			return await axios.post('/api/login', requestBody)
				.then(({data}) => {

					if (!!data) {
						localStorage.token = data.token;

						store.commit('app/SET_APIERROR', {
							status: data.status,
							message: ''
						}, {root: true});
						return true;
					} else {
						// return errorCode later
						return false;
					}
				})
				.catch(({response}) => {

					// client received an error response (5xx, 4xx)
					if (response) {
						//console.log('Error in $getAuthToken', response.status);

						store.commit('app/SET_APIERROR', {
							status: response.status,
							message: ''
						}, {root: true});
						return false;

					} else if (response.request) { // client never received a response, or request never left
						return false;
					} else {
						return false;
					}
				});
		};

		/**
		 * Authorization: Bearer Token
		 */
		Vue.prototype.$getUser = async () => {
			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			return await axios.get('/api/user')
				.then(({data}) => {
					const diveCount = store.state.app.diveCount;
					const exploreOpening = new Date(store.state.app.exploreOpening);
					let activeLevel = 0;

					// set active Level depending on played dives and date
					if (data) {
						activeLevel = data.currentDive >= diveCount && new Date().getTime() >= exploreOpening.getTime() ? 2 : 1;
					}


					// todo: check is valid
					if (
						data
					) {
						//console.log('user data', data);
						store.dispatch('user/SET_BASIC_USER_DATA', {
							firstName: data.firstName ? data.firstName : null,
							lastName: data.lastName ? data.lastName : null,
							currentDive: data.currentDive ? data.currentDive : 0,
							isAnonymous: data.isAnonymous ? data.isAnonymous : null,
							userFromApi: true,
							language: data.language ? data.language : '',
							errorCode: null
						}, {root: true});

						// setup dive and nav
						if (data.currentDive
							&& data.currentDive > 0
						) {
							// unlock and start first dive
							store.commit('dive/SET_ACTIVEDIVESTEP', {activeDiveStep: 0});
							store.commit('app/SET_IS_FIRSTTIME', {isFirstTime: false});

							store.commit('dive/SET_DIVE_INDEX', {diveIndex: data.currentDive});
							store.commit('user/SET_CURRENTDIVE', {currentDive: data.currentDive});

							store.commit('app/SET_UNLOCKED_LEVEL', {unlockedLevel: activeLevel});
							store.commit('app/SET_ACTIVE_LEVEL', {activeLevel: activeLevel});

						}

						// TODO: If currentDive >= divecount --> unlock explore

						return true;
					} else if (
						// is first time
						data &&
						data.currentDive === 0
					) {
						store.commit('app/SET_IS_FIRSTTIME', {isFirstTime: true});

						return true;
					} else {
						return false;
					}

				})
				.catch((err) => {

					store.commit('customer/SET_ERRORCODE', {
						errorCode: err.response.status
					},);

					return false;
				});
		};


		// If no data get from API
		Vue.prototype.$putUser = async (user) => {
			/**
			 * Authorization: Bearer Token
			 *
			 * requestBody: {
			 *    firstName: '',
			 *    lastName: '',
			 *    currentDive: 0, toDO: send when dive is finished
			 *    isAnonymous: true
			 * }
			 */

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
			if (
				user &&
				user.firstName &&
				user.lastName &&
				user.currentDive !== null && user.currentDive !== undefined &&
				user.isAnonymous !== null && user.isAnonymous !== undefined
			) {


				await store.dispatch('user/SET_BASIC_USER_DATA', {
					firstName: user.firstName,
					lastName: user.lastName,
					currentDive: user.currentDive,
					isAnonymous: user.isAnonymous,
					userFromApi: false,
					errorCode: null,
					language: 'de'
				}, {root: true});

				const requestBody = store.getters['user/user'];

				return await axios.put('/api/user', requestBody)
					.then(() => {
						return true;
					})
					.catch(err => {
						store.commit('user/SET_ERRORCODE', {
							errorCode: err.response.status
						},);
						return false;
					});

			} else {
				console.error('Missing required data to make API call');
				return false;
			}
		};

		/**
		 * GET all (text) information for different steps of a dive
		 *
		 * @param {String} diveID
		 *
		 *  * Authorization: Bearer Token

		 */
		Vue.prototype.$getDive = async () => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			return await axios.get('/api/dives')
				.then((res) => {

					// todo: check is valid
					if (res.data) {
						const {data} = res;

						// set fetched data in store
						store.dispatch('dive/SET_DIVE_DATA', {dives: data.dives}, {root: true});

						return true;
					} else {
						return false;
					}
				})
				.catch((err) => {
					store.commit('user/SET_ERRORCODE', {
						errorCode: err.response.status
					},);
					return false;
				});

		};

		Vue.prototype.$getDiveCount = async () => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			return await axios.get('/api/dives/count')
				.then(({data}) => {

					// todo: check is valid
					if (data) {

						// set fetched data in store
						store.commit('app/SET_DIVE_COUNT', {
							diveCount: data.divesCountAll
						},);

						return true;
					} else {
						return false;
					}
				})
				.catch((err) => {
					store.commit('user/SET_ERRORCODE', {
						errorCode: err.response.status
					},);
					return false;
				});

		};
		Vue.prototype.$putAnswer = async (dive) => {
			/**
			 * Authorization: Bearer Token
			 *
			 * requestBody:  {
			 * "answer":"John Does Answer",
			 * "diveIndex": 0,
			 * }
			 */

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			if (
				dive
			) {

				await store.dispatch('dive/SET_BASIC_DIVE_DATA', {
					answer: dive.answer,
					diveIndex: dive.diveIndex
				}, {root: true});

				const requestBody = store.getters['dive/dive'];

				return await axios.put('/api/answer', requestBody)
					.then((res) => {
						const {data} = res;
						console.log('PUT answer', data);
						return true;
					})
					.catch(err => {
						console.log(err, '$putAnswers');

					});

			}
		};


		Vue.prototype.$getAnswerList = async () => {
			/**
			 * Get Answerlist for Slider Integration
			 * Authorization: Bearer Token
			 *
			 * requestBody:  {
			 * "diveIndex": diveIndex,
			 * }
			 */

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			const requestBody = {
				params: {
					diveIndex: store.state.user.currentDive
				}
			};

			return await axios.get('/api/list/answers', requestBody)
				.then((res) => {

					if (res.data) {
						const {data} = res;
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getAnswerList ', err);
					return false;
				});

		};


		Vue.prototype.$putVoteCreative = async () => {
			/**
			 * Put Creative Vote to API
			 * Authorization: Bearer Token
			 *
			 * requestBody:  {
			 * "answerId": voteCreative,
			 * }
			 */

			const requestBody = store.getters['vote/creative'];

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;


			return await axios.put('/api/vote/creative', requestBody)
				.then((res) => {

					if (res.data) {
						const {data} = res;
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $putVoteCreative ', err);
					return false;
				});

		};

		Vue.prototype.$putVoteCorrect = async () => {
			/**
			 * Put Correct Vote to API
			 * Authorization: Bearer Token
			 *
			 * requestBody:  {
			 * "answerId": voteCorrect,
			 * }
			 */

			const requestBody = store.getters['vote/correct'];

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;


			return await axios.put('/api/vote/correct', requestBody)
				.then((res) => {

					if (res.data) {
						const {data} = res;
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $putVoteCorrect ', err);
					return false;
				});

		};


		/**
		 *
		 * Explore related Requests
		 *
		 */

		/**
		 * GET Number of all active Users from API
		 * Authorization: Bearer Token
		 *
		 */
		Vue.prototype.$getActiveUsers = async () => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;


			return await axios.get('/api/active/users')
				.then(({data}) => {

					if (data) {
						return data.activeUsers;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getActiveUsers ', err);
					return false;
				});
		};


		/**
		 * GET Number of all Votes given from API
		 * Authorization: Bearer Token
		 *
		 */
		Vue.prototype.$getTotalVotes = async () => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;


			return await axios.get('/api/answer/stats')
				.then(({data}) => {

					if (data) {
						return data.totalVotes;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getTotalVotes ', err);
					return false;
				});
		};



		/**
		 * GET all answers of the current dive to show directly after dive
		 * Authorization: Bearer Token
		 *
		 */
		Vue.prototype.$getExploreAnswers = async (diveIndex) => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			const parameter = {
				params: {
					diveIndex: diveIndex
				}
			};

			return await axios.get('/api/explore/answers', parameter)
				.then(({data}) => {

					if (data) {
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getExploreAnswers ', err);
					return false;
				});
		};



		/**
		 * GET titles for first card of explore swiper
		 * Authorization: Bearer Token
		*/
		Vue.prototype.$getExploreTitle = async (diveIndex) => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			const parameter = {
				params: {
					diveIndex: diveIndex
				}
			};

			return await axios.get('/api/explore/title', parameter)
				.then(({data}) => {

					if (data) {
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getExploreTitle ', err);
					return false;
				});
		};



		/**
		 * GET all stats regarding given dive
		 * Authorization: Bearer Token
		 *
		 */
		Vue.prototype.$getDiveStats = async (diveIndex) => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;

			const parameter = {
				params: {
					diveIndex: diveIndex
				}
			};

			return await axios.get('/api/dive/stats', parameter)
				.then(({data}) => {

					if (data) {
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getTotalVotes ', err);
					return false;
				});
		};


		/**
		 * GET Number of all Votes given from API
		 * Authorization: Bearer Token
		 *
		 */
		Vue.prototype.$getUserStats = async () => {

			Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;


			return await axios.get('/api/users/stats')
				.then(({data}) => {

					if (data) {
						//console.log(data, 'USER STATS');

						store.dispatch('user/SET_USERS_STATS', {stats: data}, {root: true});
						return data;
					} else {
						return false;
					}
				})
				.catch((err) => {
					console.error('ERR in $getTotalVotes ', err);
					return false;
				});
		};

	},
};
export default Api;
