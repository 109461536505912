export default {
	namespaced: true,
	state: {
		currentSlide: 0,
		sliderLength: 0,
	},
	mutations: {
		SET_CURRENTSLIDE(state, payload) {
			state.currentSlide = payload.currentSlide;
		},
		SET_SLIDERLENGTH(state, payload) {
			state.sliderLength = payload.sliderLength;
		},
	}
};
