export default {
	namespaced: true,
	state: {
		zipCode: null,
	},
	mutations: {
		SET_ZIP_CODE(state, payload) {
			state.zipCode = payload.zipCode;
		},
	},
	actions: {
		SET_BASIC_LOCATION_DATA({ state }, payload) {
			state.zipCode = payload.zipCode;
		},
	},
};
