<template>
	<p-card class="m-answer-card">

		<template v-if="data.type !== 'title'">

			<!-- header with category -->
			<div class="m-answer-card__header" v-if="showHeader">
				<img :src="require('@/assets/images/icons/explore/' + $t(`explore.answerCard.${data.category}.icon`) + '.svg')"
					:alt="type + ' category'"
					class="m-answer-card__type-icon"
				/>
				<p-tagline :text="$t(`explore.answerCard.${data.category}.title`)" />
			</div>

			<!-- answer text with username -->
			<div class="m-answer-card__answer">
				<p class="m-answer-card__body-text">
					{{data.answer}}
				</p>
				<p-tagline v-if="data.userName" :text="data.userName" />

				<p-tagline v-if="data.userFirstName" :text="data.userFirstName + ' ' + data.userLastName" />
			</div>

			<!-- comment with author -->
			<div class="m-answer-card__comment" v-if="showComment">
				<p-tagline :text="$t('explore.answerCard.comment')" />
				<p class="m-answer-card__body-text">
					{{data.comment}}
				</p>
				<div v-if="data.author === 'Tauch-Buddies'" class="m-answer-card__flag">
					<p-tagline :text="data.author" />
					<img :src="require('@/assets/images/icons/explore/' + $t(`explore.answerCard.perfectAnswer.icon`) + '.svg')"
						alt="perfect answer flag"
						class="m-answer-card__flag-icon"
					/>
				</div>
				<p-tagline v-else :text="data.author" />
			</div>

			<!-- perfect answer flag -->
			<div class="m-answer-card__flag" v-if="data.type === 'perfectAnswer'">
				<p-tagline :text="$t('explore.answerCard.perfectAnswer.title')" />
				<img :src="require('@/assets/images/icons/explore/' + $t(`explore.answerCard.${type}.icon`) + '.svg')"
					alt="perfect answer flag"
					class="m-answer-card__flag-icon"
				/>
			</div>

		</template>

		<p-headline v-else :text="data.title" h2 :hasMarginTop="false"/>

	</p-card>
</template>

<script>
	/**
	 * Renders a card to show the answer of an user in a slider.
	 *
	 * An answer card is made of the following parts:
	 *
	 * - header
	 * - answertext
	 * - comment
	 * - flag
	 *
	 * @displayname Answer Card
	 *
	 * @example
	 * // prepare items array
	 * let items = [
	 * 	{
	 * 		id: '123',
	 * 		text: 'This is a text.',
	 *  	category: 'correct'
	 * 	}
	 * ];
	 *
	 *
	 */
	export default {
		name: 'm-answer-card',
		data() {
			return {
				type: this.data.type
			};
		},
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		computed: {
			showHeader() { return  this.data.category && this.data.type !== 'perfectAnswer'; },
			showComment() { return this.data.comment && this.data.category !== 'short'; },

			isPerfectAnswer() { return this.data.type === 'perfectAnswer'; }
		},
	};
</script>
