import de from './de';
import en from './en';
import fr from './fr';
import ru from './ru';
import pl from './pl';

export default {
	de: de,
	en: en,
	fr: fr,
	ru: ru,
	pl: pl,
};
