<template>
	<a
		class="p-text-link"
		:class="{'p-text-link--disabled' : disabled}"
		:disabled="disabled"
		:href="link"
		:target="target"
	>
	
		<span v-if="hasIcon" class="p-text-link__icon">
			<svg viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path  d="M13.9434 0L20.8494 6.90604L20.8453 6.91016L20.8494 6.91427L13.9434 13.8203L12.8827 12.7597L17.9822 7.66016H0V6.16016H17.9822L12.8827 1.06066L13.9434 0Z" fill="#1D1DFF"/>
			</svg>
		</span>

		{{ text }}

	</a>
</template>

<script>
	export default {
		name: 'p-button',
		props: {
			text: {
				type: String,
				required: true,
			},
			link: {
				type: String,
				required: true,
			},
			target: {
				type: String,
				required: false,
				default: '_blank'
			} ,
			disabled: {
				type: Boolean,
				required: false,
				default: false
			},
			hasIcon: {
				type: Boolean,
				required: false,
				default: true
			}
		},
	};
</script>
