<template>
	<div :class="'p-radio' + disabledClass">

		<input
			:id="id"
			class="p-radio__input"
			type="radio"
			:value="optionValue"
			:name="groupName"
			:checked="isChecked"
			:disabled="disabled"
			v-on:change="$emit('input', $event.target.value)"
		/>
		<label
			:for="id"
			:class="'p-radio__label' + isActiveClass"
		>
			{{ label }}
		</label>

	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'p-radio',
		inheritAttrs: false,
		props: {
			label: {
				type: String,
				required: true,
			},
			optionValue: {
				type: String,
				required: true,
			},
			value: {
				type: String,
				required: false,
			},
			groupName: {
				type: String,
				required: true,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			changeCallback: {
				type: Function,
				required: false,
			},
		},
		data() {
			return {
				id: uuidv4(),
			};
		},
		computed: {
			disabledClass() { return this.disabled ? ' p-input--disabled' : ''; },
			isChecked() { return this.value === this.optionValue; },
			isActiveClass() { return this.isChecked ? ' p-radio__label--active' : ''; },
		},
		mounted() {
			this.id = uuidv4();
		},
		methods: {
			onChange() {
				if(this.changeCallback) {
					this.changeCallback();
				}
			},
		},
	};
</script>
