<template>
	<div class="m-dev-nav">
		<p-headline text="Developer Navigation" h3/>

		<button v-on:click="setDefaultUser">Set Default User</button>
		<button v-on:click="enableDive">enable Dive</button>
		<button	v-on:click="increaseDive">increase currentDive</button>
		<button v-on:click="allDiveSteps"	>enable all Dive Steps</button>
	</div>
</template>

<script>
	export default {
		name: 'm-dev-nav',
		computed : {
			activeLevel() { return this.$store.state.app.activeLevel; },
			unlockedLevel() { return this.$store.state.app.unlockedLevel; },
			currentDive() { 
				if(this.$store.state.user.currentDive > this.diveCount) {
					return this.diveCount;
				} else if(this.$store.state.user.currentDive < 1) {
					return 1;
				} else {
					return this.$store.state.user.currentDive;
				}
			},
			diveCount() { return this.$store.state.app.diveCount; },

			indicatorClass() { return this.isMounted ? ' p-side-nav_indicator--active' : ''; }
		},
		watch:{
		
		},
		mounted: function() {
		},
		methods : {
			setDefaultUser() {
				this.$store.commit('user/SET_FIRSTNAME', { firstName: 'Hein' });
				this.$store.commit('user/SET_LASTNAME', { lastName: 'Blöd' });
			},
			enableDive() {
				this.$store.commit('user/SET_CURRENTDIVE', { currentDive: 1 });
				this.$store.commit('app/SET_UNLOCKED_LEVEL', { unlockedLevel: 1 });
				this.$store.commit('app/SET_ACTIVE_LEVEL', { activeLevel: 1 });
				this.$store.commit('app/SET_DIVE_COUNT', { diveCount: 2 });
			},
			increaseDive() {
				this.$store.commit('user/SET_CURRENTDIVE', { currentDive: this.currentDive + 1 });
			},
			allDiveSteps() {
				this.$store.commit('dive/SET_UNLOCKED_DIVESTEP', { unlockedDiveStep: 4});
			}
		}
	};
</script>
