<template>
	<div class="m-countdown" v-if="ready">
		{{displayDays}} Tage : {{displayHours}} Std : {{displayMinutes}} Min : {{displaySeconds}} Sek
	</div>
</template>

<script>
	/**
	 *  countdown component to render a countdown and display remaining days, hours, minutes, seconds
	 *
	 * @property { string } endDate valid JS Date
	 *
	 * @example <m-countdown endDate="2021-07-16T18:00:00"/>
	 *
	 */
	export default {
		name: 'm-fraction',
		props: {
			endDate: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				displayDays: 0,
				displayHours: 0,
				displayMinutes: 0,
				displaySeconds: 0,
				finished: false,
				ready: false,
				timer: null
			};
		},
		computed: {
			seconds:() => 1000,
			minutes() { return this.seconds * 60; },
			hours() { return this.minutes * 60; },
			days() { return this.hours * 24; }
		},

		// start countdown automatically after component is mounted
		mounted() {
			if(this.endDate) this.showCountdown();
		},

		// clear timer before component destroy
		beforeDestroy() {
			if(this.timer) clearInterval(this.timer); this.timer = null;
		},

		methods: {
			showCountdown() {
				this.timer = setInterval(() => {
					const now = new Date();
					const end = new Date(this.endDate);
					const difference = end.getTime() - now.getTime();

					// console.log('timer');

					if(difference <= 0) {
						this.finished = true;
						clearInterval(this.timer);
						this.timer = null;
						this.ready = true;
						return;
					}

					this.displayDays = Math.floor(difference / this.days);
					const hours = Math.floor((difference % this.days) / this.hours);
					const minutes = Math.floor((difference % this.hours) / this.minutes);
					const seconds = Math.floor((difference % this.minutes) / this.seconds);

					this.displayHours = this.formatNumber(hours);
					this.displayMinutes = this.formatNumber(minutes);
					this.displaySeconds = this.formatNumber(seconds);

					if(!this.ready) this.ready = true;
				}, 1000);
			},
			formatNumber(number) {
				return number < 10 ? '0' + number : number;
			}
		}
	};
</script>
