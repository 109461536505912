<template>
	<div class="p-card">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'p-card',
	};
</script>
