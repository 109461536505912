<template>
	<span class="m-fraction">
		<span class="m-fraction__character m-fraction__character--top">{{this.characterTop}}</span>
		<span class="m-fraction__character">{{this.characterBottom}}</span>
	</span>
</template>

<script>
	export default {
		name: 'm-fraction',
		props: {
			characterTop: {
				type: String,
				required: true,
				default: '0'
			},
			characterBottom: {
				type: String,
				required: true,
				default: '0'
			}
		}
	};
</script>
