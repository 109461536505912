export default {
	namespaced: true,
	state: {
		isLoading: false,
		isSliderOverflow: false,
		isFirstTime: true, // init: true
		hideNavigation: false,
		step: process.env.VUE_APP_START_STEP ? parseInt(process.env.VUE_APP_START_STEP) : 1,
		activeLevel: 0,
		unlockedLevel: 0, // init: 0 --> unlocked play level (ready, dive, explore)
		diveCount: 0, // the number of all dives to play in this XDive
		exploreOpening: '2021-10-24T12:00:00',
		apiError: {
			status: null,
			message: null,
		}
	},
	mutations: {
		SET_IS_LOADING(state, {isLoading}) {
			state.isLoading = isLoading;
		},
		SET_IS_SLIDEROVERFLOW(state, isSliderOverflow) {
			state.isSliderOverflow = isSliderOverflow;
		},
		SET_IS_FIRSTTIME(state, payload) {
			state.isFirstTime = payload.isFirstTime;
		},
		SET_STEP(state, payload) {
			state.step = payload.step;
		},
		SET_NAV_STATE(state, payload) {
			state.hideNavigation = payload.hideNavigation;
		},
		SET_ACTIVE_LEVEL(state, payload) {
			state.activeLevel = payload.activeLevel;
		},
		SET_UNLOCKED_LEVEL(state, payload) {
			state.unlockedLevel = payload.unlockedLevel;
		},
		SET_DIVE_COUNT(state, payload) {
			state.diveCount = payload.diveCount;
		},
		SET_APIERROR(state, payload) {
			state.apiError.status = payload.status;
			state.apiError.message = payload.message;
		}
	},
	actions: {
		// TODO:  fetch persistent data from API
		SET_DIVE_COUNT(state, payload) {
			state.diveCount = payload.diveCount;
		},
		SET_ACTIVE_LEVEL(state, payload) {
			state.activeLevel = payload.activeLevel;
		},
		SET_UNLOCKED_LEVEL(state, payload) {
			state.unlockedLevel = payload.unlockedLevel;
		},
	}
};
