export default {
	namespaced: true,
	state: {
		type: null,
		solution: null,
		comment: null,
	},
	mutations: {
		SET_TYPE(state, payload) {
			state.type = payload.type;
		},
		SET_SOLUTION(state, payload) {
			state.solution = payload.solution;
		},
		SET_COMMENT(state, payload) {
			state.comment = payload.comment;
		},
	},
	actions: {
		SET_BASIC_APPOINTMENT_DATA({ state }, payload) {
			if(payload.type) {
				state.type = payload.type;
			}
			if(payload.solution) {
				state.solution = payload.solution;
			}
			if(payload.comment) {
				state.comment = payload.comment;
			}
		},
		CLEAR_BASIC_APPOINTMENT_DATA({ state }) {
			state.type = null;
			state.solution = null;
			state.comment = null;
		},
	},
	getters: {
		appointment: (state) => {
			const appointment = {};
			if(state.type) {
				appointment.type = state.type;
			}
			if(state.solution) {
				appointment.solution = state.solution;
			}
			if(state.comment) {
				appointment.comment = state.comment;
			}
			return appointment;
		},
	},
};
