<template>
	<div
		class="m-modal"  :class="{' grid-container ' : gridPositioning}"
	>
		<div class="m-modal__card"  :class="{' offset-3-column-11 ' : gridPositioning}">
			<p-headline
				:hasMarginTop="false"
				:text="headline" h2
			/>
			<p-text
				v-if="copy"
				:text="copy"
			/>
			<slot></slot>
			<div class="m-modal__button-wrapper">
				<p-button
					:text="secondaryActionText"
					color="secondary"
					:onClick="onSecondaryAction"
				/>
				<p-button
					v-if="!this.hideButton"
					class="m-modal__primary-button"
					:text="primaryActionText"
					color="fillup"
					:onClick="onPrimaryAction"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'm-modal',
		props: {
			gridPositioning: {
				type: Boolean,
				required: false,
				default: true
			},
			headline: {
				type: String,
				required: true,
			},
			copy: {
				type: String,
				required: false,
			},
			primaryActionText: {
				type: String,
				required: false,
			},
			onPrimaryAction: {
				type: Function,
				required: false,
			},
			secondaryActionText: {
				type: String,
				required: false,
			},
			onSecondaryAction: {
				type: Function,
				required: false,
			},
			hideButton: {
				type: Boolean,
				required: false,
				default: false
			},
		},
	};
</script>
