<template>
	<div class="p-action">
		<m-form
			v-if="!showModal"
			:handle="handleForm"
		>
			<m-text-input
				:headline="headline"
				:button="button"
				:buttonSecondary="$t('dive.buttons.actionSkip')"
				:onClickSecondary="handleSecondaryClick"
				:copy="copy"
				:buttonDisabled="false"
				:textarea="false"
				placeholder=""
			>
				<div class="column-6">
					<p-input
						v-on:inputUpdate="handleInput"
						:value="answerFromStore"
						placeholder="Man könnte sagen..."
						:maxLength="280"
						textarea
						counter />
				</div>
			</m-text-input>
		</m-form>

		<!-- Skip Modal -->
		<m-modal
			v-if="showModal === MODAL_SKIP"
			:headline="modalHeadline"
			:copy="modalCopy"
			:primaryActionText="modalActionText"
			:onPrimaryAction="() => handleModal()"
			:secondaryActionText="modalSecondaryActionText"
			:onSecondaryAction="() => hideModal()"
		/>

		<!-- Empty -->
		<m-modal
			v-if="showModal === MODAL_EMPTY"
			:headline="modalHeadline"
			:primaryActionText="modalActionText"
			:onPrimaryAction="() => handleModal()"
			:secondaryActionText="modalSecondaryActionText"
			:onSecondaryAction="() => hideModal()"
		/>
	</div>
</template>

<script>
	import { eventBus } from '@/main';
	export default {
		name: 'p-action',
		props: {
			diveIndex: {
				type: Number,
				required: true,
			}
		},
		computed: {
			headline() { return this.$store.state.dive.diveContent[this.diveIndex].action.title; },
			copy() { return this.$store.state.dive.diveContent[this.diveIndex].action.text; },
			answerFromStore() { return this.$store.state.dive.answer; },
			button() { return this.$store.state.dive.diveContent[this.diveIndex].action.buttonText; },
			isTimerRunning() { return this.$store.state.userMenu.isTimerRunning; },

			showModal() { return this.$store.state.dive.showModal; },
			modalHeadline() { return this.showModal === this.MODAL_SKIP ? this.$t('dive.skipModal.headline') : this.$t('dive.emptyModal.headline');},
			modalCopy() { return this.showModal === this.MODAL_SKIP ? this.$t('dive.skipModal.copy') : null; },
			modalActionText() { return  this.showModal === this.MODAL_SKIP? this.$t('dive.skipModal.primaryButton') : this.$t('dive.emptyModal.primaryButton');},
			modalSecondaryActionText() { return  this.showModal === this.MODAL_SKIP? this.$t('dive.skipModal.secondaryButton') : this.$t('dive.emptyModal.secondaryButton');}
		},
		data() {
			return {
				answer: null,
				MODAL_EMPTY: 'emptyModal',
				MODAL_SKIP: 'skipModal'
			};
		},
		created() {
			this.$scrollPageToTop();
			this.answer = this.answerFromStore;
		},
		beforeDestroy() {
			this.$store.commit('userMenu/SET_IS_TIMER_RUNNING' , {isTimerRunning : false});
			// set answer in store to restore it on revisit
			this.$store.commit('dive/SET_ANSWER', { answer: this.answer });

			// TODO: pause timer and restore time on revisit
		},
		methods: {
			handleInput(input) {
				if(!this.isTimerRunning) eventBus.$emit('startTimer'); // only for development. TODO: move to input on focus?

				if(input.value !== '' && input.value !== null) {
					this.answer = input.value;
				} else {
					this.answer = null;
				}
			},
			async handleForm() {

				if(this.answer === null) {
					this.$store.commit('dive/SET_SHOW_MODAL', { showModal: this.MODAL_EMPTY }, { root: true });
					return;	
				} 

				// send to store
				await this.$store.dispatch('dive/SET_BASIC_DIVE_DATA', {
					answer: this.answer,
					diveIndex: this.$store.state.user.currentDive
				}, { root: true });

				this.nextDiveStep();
			},
			nextDiveStep() {
				this.$store.commit('dive/SET_ACTIVEDIVESTEP', { activeDiveStep: 4 }, { root: true });
			},
			handleSecondaryClick() {
				this.$store.commit('dive/SET_SHOW_MODAL', { showModal: this.MODAL_SKIP }, { root: true });
			},
			handleModal() {
				this.hideModal();
				this.nextDiveStep();
			},
			hideModal() {
				this.$store.commit('dive/SET_SHOW_MODAL', { showModal: null }, { root: true });
			}
		}
	};
</script>
