<template>
	<div
		v-if="items && items.length > 0"
		class="m-slideshow"
	>

		<p-tagline v-if="title" :text="title" class="p-slider__tagline-title"></p-tagline>
		
		<v-vueper-slides
			:ref="category + 'Slider'"
			:key="category + 'Slider'"

			progress
			:preventYScroll="false"
			disableArrowsOnEdges
			:touchable="true"
			:bullets="false"
			:arrowsOutside="true"
			:parallax="false"
			:infinite="false"
			class="no-shadow"

			:visible-slides="2"
			:dragging-distance="30"
			:breakpoints="{ 1024: { visibleSlides: 1 } }"
		>

			<v-vueper-slide
				v-for="(slide, index) in items"
				:key="'slide-'+ category + index"
			>
				<template v-slot:content>

					<m-headline-card v-if="index === 0 && headlineCard" :id="slide.id" :text="slide[category]"/>
					<m-vote-card  v-else :id="slide.id" :text="slide.answer" :category="category"/>

				</template>
			</v-vueper-slide>


			<template v-slot:arrow-left class="p-slider__arrow  p-slider__arrow--prev">
				<svg viewBox="0 0 14 24" class="p-slider__arrow-icon">
					<path d="M0.812988 12.0012L12.1267 0.6875L13.5409 2.10171L2.2272 13.4154L0.812988 12.0012Z" fill="#1D1DFF"/>
					<path d="M0.812988 12.0012L12.1267 23.3149L13.5409 21.9007L2.2272 10.587L0.812988 12.0012Z" fill="#1D1DFF"/>
				</svg>

			</template>

			<template v-slot:progressbar>
			</template>

			<template v-slot:arrow-right class="p-slider__arrow  p-slider__arrow--next">
				<svg viewBox="0 0 14 24" class="p-slider__arrow-icon">
					<path d="M13.187 12.0012L1.8733 0.6875L0.45909 2.10171L11.7728 13.4154L13.187 12.0012Z" fill="#1D1DFF"/>
					<path d="M13.187 12.0012L1.8733 23.3149L0.45909 21.9007L11.7728 10.587L13.187 12.0012Z" fill="#1D1DFF"/>
				</svg>

			</template>

		</v-vueper-slides>
	</div>
</template>

<script>
	/**
	 * Renders a slider which contains cards to vote.
	 * @displayname Vote Slider
	 *
	 *
	 *
	 * @example
	 * // prepare items array
	 * let items = [
	 * 	{
	 * 		id: '123',
	 * 		text: 'This is a text.',
	 *  	category: 'correct'
	 * 	}
	 * ];
	 *
	 * // add headline to items
	 * this.items.unshift({text: this.$i18n.t('dive.voteHeadlines.vote1')});
	 *
	 */

	import { VueperSlides, VueperSlide } from 'vueperslides';
	import { eventBus } from '@/main';
	export default {
		name: 'p-slider',
		components: {
			'v-vueper-slides': VueperSlides,
			'v-vueper-slide': VueperSlide,
		},
		props: {
			/**
			 * items to show in the slider.
			 * One item should be an Object with the following structe:
			 * {
			 * 	id: String,
			 * 	text: String,
			 * 	category: String
			 * }
			 */
			items: {
				type: Array,
				required: true,
			},
			/**
			 * Use the first card to render a headline or not.
			 *
			 * If you want to render a headline you have to add the headline object
			 * to the first index of the items array. Check the example of the component.
			 */
			headlineCard: {
				type: Boolean,
				required: false,
				default: false
			},
			title: {
				type: String,
				required: false,
				default: null
			},
			category: {
				type: String,
				required: true,
			},
			visibleSlides: {
				type: Number,
				required: false,
				default: 3
			}
		},
		created() {
			/**
			 * Register listener on 'startTimer' event to start the timer
			 * Trigger this function in other component by emitting 'startTimer'
			 *
			 * Example:
			 * import { eventBus } from '@/main'
			 *
			 * onClick() {eventBus.$emit('startTimer');}
			 */
			eventBus.$on('sliderNext', () => {
				this.slideToNext();
			});
			eventBus.$on('sliderPrevious', () => {
				this.slideToPrevious();
			});

			// toggle overflow on grid
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
		},
		beforeDestroy: function() {
			eventBus.$off('sliderNext');
			eventBus.$off('sliderPrevious');

			// toggle overflow on grid
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', false);
		},
		methods: {
			// Moves the slider 1 position forwards
			slideToNext() {
				this.$refs.correctSlider.next();
			},
			// Moves the slider 1 position backwards
			slideToPrevious() {
				this.$refs.correctSlider.previous();
			}
		}
	};
</script>
