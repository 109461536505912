<template>
	<div class="m-loading-slider">

		<template v-for="col in columns">

			<div class="m-loading-slider-col" :key="'skeleton-col-' + col">
				<template v-for="row in rows">

					<p-card class="m-loading-slider-row" :key="'skeleton-row-' +  row">
						<div class="m-loading-slider__content m-loading-slider__content--paragraph">
						</div>
						<div class="m-loading-slider__content m-loading-slider__content--paragraph">
						</div>
						<div class="m-loading-slider__content m-loading-slider__content--paragraph">
						</div>
						<div class="m-loading-slider__content m-loading-slider__content--paragraph">
						</div>
						<div class="m-loading-slider__content m-loading-slider__content--parapgraph-end">
						</div>
						<div class="m-loading-slider__content m-loading-slider__content--parapgraph-end">
						</div>
					</p-card>

				</template>
			</div>

		</template>
	</div>
</template>

<script>
	export default {
		name: 'm-loading-slider',
		props: {
			rows: {
				type: Number,
				required: false,
				default: 2,
			},
			columns: {
				type: Number,
				required: false,
				default: 5,
			}
		},
		computed: {
		},
	};
</script>
