export default {
	'dive': {
		'anonymousModal': {
			'copy': 'Do you agree that your answers by name will be used internally even after the XDive congstar expires (e.g. wiki, internal presentations)?',
			'headline': 'EN: What great results!',
			'primaryButton': 'Yes, gladly',
			'secondaryButton': 'Only anonymously please'
		},
		'buttons': {
			'actionSend': 'dispatching',
			'actionSkip': 'omit',
			'consideratio': 'Understood!',
			'example': 'Exciting!',
			'szenario': "Let's go!"
		},
		'emptyModal': {
			'headline': 'EN: Oops, your answer is still empty. Was that on purpose?',
			'primaryButton': 'Further',
			'secondaryButton': 'back'
		},
		'introComplete': {
			'button': 'Get in the water!',
			'copy1': "You've got them all",
			'copy2': 'graduated! Thank you so much! You can now pop out of the XDive and close the window. The time until the results are released, but we will also inform you by email again.',
			'headline': 'EN: Mega performance!',
			'linkHref': 'https://denkwerk.com',
			'linkText': 'wiki.'
		},
		'introFTU': {
			'button': 'Get in the water!',
			'copy1': '',
			'copy2': 'For everyone',
			'copy3': 'do you need ',
			'copyTime': '— or a few times five to ten minutes. Have fun.',
			'headline': 'EN: Everything ready!'
		},
		'introWelcomeBack': {
			'button': 'Resume dive',
			'copy1': "Glad you're taking the time again. You're already in Dive",
			'copy2': 'Now take a deep breath and...',
			'headline': 'EN: Welcome back!'
		},
		'skipModal': {
			'copy': 'A tip against the blockade: Write down a few keywords first and then make the appropriate sentences out of them.',
			'headline': "EN: Don't you want to give it a try?",
			'primaryButton': 'Further',
			'secondaryButton': 'Zurück'
		},
		'tabNav': {
			'action': 'action',
			'consideration': 'deliberation',
			'example': 'instance',
			'szenario': 'scenario',
			'vote1': 'vote 1',
			'vote2': 'vote 2'
		}
	},
	'error': {
		'notAuthorized': {
			'copy': "Sorry, but this <strong><i>link is not valid</i></strong> or you're signed in from a different device. <br><br>To take part in the dive, please use the <strong><i>link</i></strong> you received from us <strong><i>via email</i></strong>. <br><br>Till now!",
			'headline': 'EN: Oops, a blind passenger!',
			'img': ''
		},
		'pageNotFound': {
			'button': 'Back to dive',
			'copy': "Oops — looks like you've immersed yourself! <br><br>There's almost nothing down here anymore. Not even the page you're looking for.",
			'headline': 'EN: 404',
			'img': ''
		}
	},
	'explore': {
		'answerCard': {
			'bestVoteCorrect': {
				'icon': 'Correct',
				'title': 'Your doerken pick'
			},
			'bestVoteCreative': {
				'icon': 'Creative',
				'title': 'Your creative pick'
			},
			'ceoPick': {
				'icon': 'CeoPick-30px',
				'title': "POP's Pick"
			},
			'comment': 'remark',
			'fun': {
				'icon': 'FunnyAnswer-30px',
				'title': 'Extremely fun'
			},
			'learning': {
				'icon': 'Learning-30px',
				'title': 'Extremely exciting'
			},
			'perfectAnswer': {
				'icon': 'snorkler flag',
				'title': 'Diving buddies'
			},
			'short': {
				'icon': 'ShortestAnswer-30px',
				'title': 'Shortest answer'
			},
			'userAnswer': {
				'icon': 'User',
				'title': 'Your Response'
			}
		},
		'batchModal': {
			'headline': "EN: What a great yield! And that wasn't all...",
			'headlineLastItem': "Wow, either you just read about 45 minutes or you were curious about what was coming here. Either way, let's go even deeper!",
			'primaryButton': 'Next dive',
			'secondaryButton': 'Show more'
		},
		'intro': {
			'button': "Let's go",
			'copy': "Hidden in the depths, already in the spotlight today: <strong><i>your best solutions</i></strong>. It's high time to see the <strong><i>treasures of the XDive</i></strong>.",
			'headline': "EN: Diver's high!"
		},
		'overview': {
			'buttonDesktop': 'Discover Yield',
			'buttonMobile': 'yield',
			'headline': 'EN: madness, '
		},
		'playerCard': {
			'award': 'Excellent answer',
			'correct': 'Full doerken reply',
			'creative': 'Most creative answers',
			'givenAnswers': 'Full participation',
			'headline': 'EN: Just great — your diving highlights:',
			'winner': 'Winner answer'
		},
		'tabNav': {
			'dive': {
				'0': 'Dives',
				'1': 'dive'
			},
			'overview': 'overview'
		},
		'teamCard': {
			'diveTime': 'minutes dive time',
			'headline': 'EN: Totally strong — this team result is pure gold:',
			'perspectives': 'prospects',
			'totalAwards': 'awards'
		}
	},
	'firstLaunch': {
		'intro': {
			'button': '3.. 2.. 1.. go!',
			'copy': "Together we dive into the <strong><i>congstar experience</i></strong>. Glad you're in!",
			'headline': 'EN: Welcome!'
		},
		'yourName': {
			'fieldFirstName': {
				'name': 'Firstname',
				'placeholder': 'Forename',
				'type': 'Name'
			},
			'fieldLastName': {
				'name': 'Lastname',
				'placeholder': 'Surname',
				'type': 'Name'
			},
			'headline': 'EN: What is your name?',
			'subline': 'First Things First',
			'submitButton': "That's me!"
		}
	},
	'login': {
		'confirm': {
			'headline': 'EN: Great, your link is on its way!',
			'text': "We've sent your personal <b><i>participant link</i></b> to your email. <br/><br/>Please check your <b><i>email inbox</i></b> and use your <b><i>link</i></b> to join the dive. <br/><br/>Till now!"
		},
		'email': {
			'button': 'Send',
			'error': 'Use @Doerken domain!',
			'fieldEmail': {
				'placeholder': 'Business Dörken mail address'
			},
			'headline': 'EN: What is your email address?',
			'subline': 'Personal access'
		},
		'language': {
			'button': 'confirm',
			'headline': 'EN: Please choose a <span class="m-headline-card__highlight">language</span>'
		}
	},
	'lorem': {
		'field': {
			'placeholder': 'Just call me',
			'type': 'Test'
		},
		'submitText': "That's me!",
		'title': 'Lorem Ipsum next Page'
	},
	'ready': {
		'button': "Let's go",
		'copy': "Welcome to the <strong><i>xDive</i></strong>. Now you can test right away if you've just been paying good attention. You can also find a summary of the <strong><i>keynote</i></strong>",
		'headline': 'EN: Hey, ',
		'linkHref': 'https://share.denkwerk.com/s/Ddzr7MjAAASeYEn',
		'linkText': 'here.'
	},
	'sideNavigation': {
		'dive': 'dive',
		'explore': 'Explore',
		'getReady': 'Get ready'
	},
	'userMenu': {
		'timerLabel': 'Dive time <br>recommendation'
	},
	'vote': {
		'headlineCard': {
			'headline': 'EN: Which of the answers is'
		},
		'modal': {
			'copy1': 'you have for ',
			'copy2': 'no vote yet. Was that on purpose?',
			'headline': "EN: Oh, you sure you don't want to mark an answer here?",
			'primaryText': 'skipping',
			'secondaryText': 'Back'
		}
	}
};
