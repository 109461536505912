export default {
	lorem: {
		title: 'Lorem Ipsum next Page',
		field: {
			placeholder: 'Nennt mich einfach',
			type: 'test'
		},
		submitText: 'Thats me'
	},
	sideNavigation: {
		explore: 'Explore',
		dive: 'dive',
		getReady: 'Get Ready'
	},
	login: {
		email: {
			subline: 'Dostęp osobisty',
			headline: 'PL: Jaki jest Twój adres e-mail?',
			error: 'Użyj domeny @doerken!',
			errorServer: 'Ups, serwer nie odpowiada. Proszę poczekać chwilę i spróbować ponownie!',
			button:'Wyślij',
			fieldEmail: {
				placeholder: 'Adres pocztowy firmy Dörken'
			}
		},
		language: {
			headline: 'PL: Please choose a <span class="m-headline-card__highlight">language</span>',
			button: 'potwierdź',
		},
		confirm: {
			headline: 'PL: Świetnie, Twój link jest już w drodze!',
			text: 'Przesłaliśmy na Twój adres e-mail Twój osobisty link do zgłoszenia.<br/><br/>\n' +
				'Sprawdź swoją skrzynkę pocztową i użyj swojego linku, aby dołączyć do nurkowania.<br/><br/> \n' +
				'Do zobaczenia wkrótce!',
		}
	},
	firstLaunch: {
		intro: {
			headline: 'PL: Willkommen!',
			copy: 'Gemeinsam tauchen wir in die <strong><i>congstar Experience</i></strong> ein. Schön, dass du dabei bist!',
			button:'3..2..1..los!'
		},
		yourName: {
			headline: 'PL: Wie heißt du?',
			subline: 'First Things First',
			fieldFirstName: {
				placeholder: 'Vorname',
				name: 'firstName',
				type: 'name',
			},
			fieldLastName: {
				placeholder: 'Nachname',
				name: 'lastName',
				type: 'name'
			},
			submitButton: 'That\'s me!'
		}
	},
	ready: {
		headline: 'PL: Hey, ',
		copy: 'Willkommen POLE zum <strong><i>XDive</i></strong>. Jetzt kannst du direkt testen, ob du gerade gut aufgepasst hast. Eine Zusammenfassung der <strong><i>Keynote</i></strong> findest du aber auch',
		linkText: 'hier.',
		linkHref: 'https://share.denkwerk.com/s/Ddzr7MjAAASeYEn',
		button: 'Los Geht\'s'
	},
	dive: {
		introFTU: {
			headline: 'PL: Alles bereit!',
			copy1: '',
			copy2: 'Für alle',
			copy3: 'brauchst du ',
			copyTime: '– oder ein paar Mal fünf bis zehn Minuten. Viel Spaß.',
			button: 'Ab in\'s Wasser!'
		},
		introWelcomeBack: {
			headline: 'PL: Willkommen zurück!',
			copy1: 'Schön, dass du dir nochmal die Zeit nimmst. Du bist schon in Dive',
			copy2: 'Jetzt noch einmal tief Luft holen und ...',
			button: 'Dive fortsetzen'
		},
		introComplete: {
			headline: 'PL: Mega Leistung!',
			copy1: 'Du hast alle',
			copy2: 'absolviert! Vielen Dank! Du kannst nun wieder aus dem XDive auftauchen und das Fenster schließen. Die Zeit bis zur Freischaltung der Ergebnisse läuft schon, wir informieren dich aber auch wieder per Mail.',
			linkText: 'Wiki.',
			linkHref: 'https://denkwerk.com',
			button: 'Ab in\'s Wasser!'
		},
		tabNav: {
			szenario: 'Szenario',
			example: 'Beispiel',
			consideration: 'Überlegung',
			action: 'Action',
			vote1: 'vote 1',
			vote2: 'vote 2'
		},
		buttons: {
			szenario: 'Let\'s Go!',
			example: 'Spannend!',
			consideratio: 'Verstanden!',
			actionSkip: 'Auslassen',
			actionSend: 'Abschicken',
		},
		skipModal: {
			headline: 'PL: Willst du es nicht doch mal probieren?',
			copy: 'Ein Tipp gegen die Blockade: Schreib zuerst ein paar Stichwörter auf und bilde erst im Anschluss die passenden Sätze daraus.',
			secondaryButton: 'Zurück',
			primaryButton: 'Weiter'
		},
		emptyModal: {
			headline: 'PL: Ups, deine Antwort ist noch leer. War das Absicht?',
			secondaryButton: 'zurück',
			primaryButton: 'Weiter'
		},
		anonymousModal: {
			headline: 'PL: Was für tolle Ergebnisse!',
			copy: 'Bist du einverstanden, dass deine Antworten mit Namen auch nach Ablauf des XDives congstar intern verwendet werden (z.B. Wiki, interne Präsentationen)?',
			secondaryButton: 'Bitte nur anonym',
			primaryButton: 'Ja, gerne'
		}
	},
	vote: {
		headlineCard: {
			headline: 'PL: Welche der Antworten ist'
		},
		modal: {
			headline: 'PL: Oh, möchtest du hier wirklich keine Antwort auszeichnen?',
			copy1: 'Du hast für ',
			copy2: 'noch keine Stimme abgegeben. War das Absicht?',
			primaryText: 'Skippen',
			secondaryText: 'Zurück'
		}
	},
	userMenu: {
		timerLabel: 'Tauchzeit <br> Empfehlung'
	},
	explore: {
		intro: {
			headline: 'PL: Diver’s high!',
			copy: 'Eben noch in den Tiefen verborgen, heute schon im Spotlight: <strong><i>Eure besten Lösungen</i></strong>. Höchste Zeit, die <strong><i>Schätze des XDives</i></strong> zu sichten.',
			button: 'Los geht\'s'
		},
		tabNav: {
			overview: 'Übersicht',
			dive: {
				0: 'Dives',
				1: 'Dive'
			}
		},
		overview: {
			headline: 'PL: Wahnsinn, ',
			buttonDesktop: 'Ausbeute Entdecken',
			buttonMobile: 'Ausbeute'
		},
		teamCard: {
			headline: 'PL: Total stark – dieses Team-Ergebnis ist pures Gold:',
			diveTime: 'Minuten Tauchzeit',
			totalAwards: 'Auszeichnungen',
			perspectives: 'Perspektiven'
		},
		playerCard: {
			headline: 'PL: Einfach großartig – deine Tauch-Highlights:',
			winner: 'Gewinner Antwort',
			givenAnswers: 'Vollständige Teilnahme',
			award: 'Ausgezeichnete Antwort',
			creative: 'Kreativste Antworten',
			correct: 'Voll congstar Antworten'
		},
		answerCard: {
			comment: 'Kommentar',
			bestVoteCreative: {
				icon: 'Creative',
				title: 'Euer Kreativ-Pick'
			},
			bestVoteCorrect: {
				icon: 'Correct',
				title: 'Euer congstar Pick'
			},
			perfectAnswer: {
				icon: 'Snorkler-flag',
				title: 'Tauch-Buddies'
			},
			ceoPick: {
				icon: 'CeoPick-30px',
				title: 'POP\'s Pick'
			},
			learning: {
				icon: 'Learning-30px',
				title: 'Besonders spannend'
			},
			fun: {
				icon: 'FunnyAnswer-30px',
				title: 'Besonders spaßig'
			},
			short: {
				icon: 'ShortestAnswer-30px',
				title: 'Kürzeste Antwort'
			},
			userAnswer: {
				icon: 'User',
				title: 'Deine Antwort'
			}
		},
		batchModal: {
			headline: 'PL: Was für eine tolle Ausbeute! Und das war noch lange nicht alles...',
			headlineLastItem: 'Wow, entweder du hast gerade ca. 45 Minuten gelesen, oder du warst neugierig, was hier kommt. So oder so: Let’s go even deeper!',
			secondaryButton: 'Mehr anzeigen',
			primaryButton: 'Nächster Dive'
		}
	},
	error: {
		pageNotFound: {
			img: '',
			headline: 'PL: 404',
			copy: 'Huch – sieht ganz so aus, als hättest du dich vertaucht! <br><br> Hier unten existiert so gut wie nichts mehr. Noch nicht mal die gesuchte Seite.',
			button: 'Zurück zum Dive'
		},
		notAuthorized: {
			img: '',
			headline: 'PL: Ups, ein blinder Passagier!',
			copy: 'Sorry, aber dieser <strong><i>Link ist ungültig</i></strong> oder du bist über ein anderes Gerät angemeldet.<br><br>Um am Dive teilzunehmen, benutze bitte den <strong><i>Link</i></strong>, den du von uns <strong><i>per Mail</i></strong> bekommen hast.<br><br>Bis gleich!',
		}
	}
};
