<template>
	<p-dive-intro
		img="DiveIntro.svg"
		:headline="$t('dive.introFTU.headline')"
		:button="$t('dive.introFTU.button')"
		:buttonClick="handleButton"
	>
		<p-text>
			{{$t('dive.introFTU.copy1')}}<br>
			{{$t('dive.introFTU.copy2')}}
		</p-text>
	</p-dive-intro>
</template>

<script>
	export default {
		name: 'p-dive-intro-ftu',
		data() {
			return {
			};
		},
		computed: {
		},
		mounted() {
		},
		methods: {
			handleButton() {
				// unlock dive level for user
				this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : 1});
				this.$store.commit('app/SET_UNLOCKED_LEVEL' , {unlockedLevel : 1});
				this.$store.commit('user/SET_CURRENTDIVE' , {currentDive : 1});

				// unlock and start first dive
				this.$store.commit('dive/SET_IS_PLAYING' , {isPlaying : true});
				this.$store.commit('dive/SET_ACTIVEDIVESTEP' , {activeDiveStep : 0});
			}
		}
	};
</script>
