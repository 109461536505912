<template>
	<div id="timer">
		<svg class="timer__background">
			<circle cx="25" cy="25" r="25" />
		</svg>
	</div>
</template>

<script>
	/**
	 *  timer component to render a countdown timer with a ciruclar progress
	 * 
	 */
	import ProgressBar from 'progressbar.js';
	import { eventBus } from '@/main';
	export default {
		name: 'p-user-menu',
		data() {
			const timerDuration = 5; // change this to set timer duration in minutes

			return {
				windowWidth: 0,
				timer: null,
				timeInSeconds: timerDuration * 60,
				interval: null,
			};
		},
		computed: {
			isMobile() { return this.windowWidth <= 1023; },
			showTimer() {
				// show on mobile?
				if(this.isMobile) {

					// depending on open menu
					return this.$store.state.dive.showUsername !== false;
					
				} else {

					// depending on current dive step
					// dive step 3 ==> "Action"
					return this.$store.state.dive.diveStep === 3;
				}
			},
			displayTime() {
				/**
				 * return readable time in MM:SS format
				 */
				const minutes = parseInt(this.timeInSeconds / 60);
				const seconds = ('0' + this.timeInSeconds % 60).slice(-2);
				return `${minutes}:${seconds}`;
			}
		},
		created: function() {

			/**
			 * Register listener on 'startTimer' event to start the timer
			 * Trigger this function in other component by emitting 'startTimer'
			 * 
			 * Example:
			 * import { eventBus } from '@/main'
			 * 
			 * onClick() {eventBus.$emit('startTimer');}
			 */
			eventBus.$on('startTimer', () => {
				this.startTimer();
			});

			eventBus.$on('resetTimer', () => {
				this.resetTimer();
			});

			window.addEventListener('resize', this.updateWindowWidth);
			this.updateWindowWidth();
		},

		mounted() {
			// initialize timer when component was mounted
			this.setTimer();
		},

		beforeDestroy() {
			// deregister event from eventbus before component was destroyed
			eventBus.$off('startTimer');
			eventBus.$off('resetTimer');
		},

		destroyed() {
			window.removeEventListener('resize', this.updateWindowWidth);
		},

		methods: {
			updateWindowWidth() {
				this.windowWidth = window.innerWidth;
			},
			setTimer() {
				// initate timer 
				this.timer = new ProgressBar.Circle('#timer',{
					duration: this.timeInSeconds * 1000,
					color: '#aaa',
					trailColor: '#1d1dff',
					strokeWidth: 2.5,
					trailWidth: 2.5,
					text: {
						value: this.displayTime,
						className: 'timer__label'
					}
				});
			},
			startTimer() {
				// don't start timer again if its already running
				if(this.interval) return;

				// Update Time
				this.interval = setInterval(() => {
					this.timeInSeconds -= 1;
					this.timer.setText(this.displayTime);
					if(this.timeInSeconds <= 0) clearInterval(this.interval);
				}, 1000);

				// let the progresscircle move counterclockwise
				this.timer.animate(-1.0);

				this.$store.commit('userMenu/SET_IS_TIMER_RUNNING' , {isTimerRunning : true});
			},
			resetTimer() {
				this.$store.commit('userMenu/SET_IS_TIMER_RUNNING' , {isTimerRunning : false});
			}
		}
	};
</script>
