<template>
	<div class="p-vote">
		<m-loading-slider v-if="isLoading" class="p-vote__loading-slider"/>
		<div class="p-vote__tab-2" v-if="!showModal && !isLoading">

			<p-slider
				:key="voteKey"
				v-if="answersVote"
				:items="answersVote"
				category="correct"
				headlineCard
				:title="$t('vote.title')"
			/>

			<p-button
				class="p-vote__button"
				:color="'submit'"
				:text="'Abschicken'"
				:onClick="() => handleVote()"
			/>
		</div>

		<m-modal
			v-if="showModal"
			:headline="$t('vote.modal.headline')"
			:primaryActionText="$t('vote.modal.primaryText')"
			:onPrimaryAction="() => handleModalPrimary()"
			:secondaryActionText="$t('vote.modal.secondaryText')"
			:onSecondaryAction="hideModal"
		/>
	</div>
</template>

<script>
	export default {
		name: 'p-vote',
		data() {
			return {
				isLoading: true,
				answersVote: null,
				voteKey: 0
			};
		},
		computed : {
			diveStep() { return this.$store.state.dive.activeDiveStep; },
			diveIndex() { return this.$store.state.dive.diveIndex; },
			voteCorrect() { return this.$store.state.vote.voteCorrect; },
			showModal() { return this.$store.state.vote.showModal; },
			isAnonymous() { return this.$store.state.user.isAnonymous; },

			currentDive() { return this.$store.state.user.currentDive; },
			diveCount() { return this.$store.state.app.diveCount; },

			answer() { return this.$store.state.dive.answer; },

			isFinished() { return this.currentDive > this.diveCount; },

			missingVotesText() {
				// if(!this.voteCorrect && !this.voteCreative) return 'Vote 1 und Vote 2';
				if(!this.voteCorrect) return 'Vote 1 ';
				
				return 'Vote 2';
			}
		},
		created() {
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
			this.fetchAnswers();
		},
		mounted() {
		},
		methods: {
			async fetchAnswers() {
				const { answers, correct } = await this.$getAnswerList();

				// TODO: redirect to error page
				if(!answers || !correct) this.navigateToErrorPage();

				this.answersVote = [{ 'correct': correct}, ...answers.slice(0,4)]; // only show 4 answers
				this.isLoading = false;
				console.log(this.answersVote);
			},

			async handleVote() {
				
				if(this.voteCorrect) {
					// make API Call and set states
					this.submitVotes();
					return;
				} else {
					this.$store.commit('vote/SET_SHOW_MODAL' , {showModal : true});
				}
			},

			async submitVotes() {
				if(this.voteCorrect) await this.$putVoteCorrect();

				if(this.answer !== '' && this.answer !== null) {
					// try push answers to backend
					const putAnswer = await this.putAnswer();
					if(!putAnswer) {
						this.navigateToErrorPage();
						return;
					}
				}

				// set diveIndex +1 if user navigated via navigation and not via the button from the "get ready" page
				if(this.diveIndex <= 0) {
					this.$store.commit('dive/SET_DIVE_INDEX', {diveIndex: 1});
				}

				// only use timer when user clicked on submit button to show animation
				if(!this.voteCorrect) {
					this.$store.dispatch('dive/START_NEXT_DIVE_STEP');
				} else {
					// pause to show button animation
					setTimeout(() => {
						this.$store.dispatch('dive/START_NEXT_DIVE_STEP');
						// TODO: check if this was the last dive
					}, 1200);
				}
			},
			/**
			 * push given answer to backend
			 *
			 * @returns true if request was successfull
			 */
			async putAnswer() {
				const putAnswer = await this.$putAnswer({ answer: this.answer, diveIndex: this.currentDive });

				if( putAnswer ) return true;

				return false;
			},

			async handleModalPrimary() {
				await this.submitVotes();
				// disable modal
				this.$store.commit('vote/SET_SHOW_MODAL' , {showModal : false});
			},

			hideModal() {
				this.$store.commit('vote/SET_SHOW_MODAL' , {showModal : false});
			},

			navigateToErrorPage() {
				this.$router.push('error', (err) => { console.error('Navigation error: ', err);});
			}
		}
	};
</script>
