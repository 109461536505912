<template>
	<div class="m-slideshow__content m-vote-card" :class="{'m-vote-card--selected': isSelected}">
		<p class="m-slideshow__text">
			{{text}}
		</p>
		<div class="m-vote-card__badge" v-on:click="handleClick(category)">
			<img v-if="category === 'correct'"
				class="m-vote-card__badge-icon" 
				src="@/assets/images/icons/ICO_Correct.svg" 
				alt="correct thumb"
			>

			<img v-if="category === 'creative'"
				class="m-vote-card__badge-icon" 
				src="@/assets/images/icons/ICO_Creative.svg" 
				alt="creative candle"
			>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'm-vote-card',
		components: {
		},
		props: {
			id: {
				type: Number,
				required: true,
			},
			text: {
				type: String,
				required: true
			},
			category: {
				type: String,
				required: true,
				default: 'correct'
			}
		},
		computed: {
			isSelected() { 
				if(this.category === 'correct') {
					return this.$store.state.vote.voteCorrect === this.id; 
				}

				return this.$store.state.vote.voteCreative === this.id;
			},
			disableVote2() { return this.$store.state.vote.disableVote2; },
		},
		methods: {
			handleClick (category) {
				if(category === 'correct') {
					this.$store.commit('vote/SET_VOTE_CORRECT', { id: this.id });
					if(this.disableVote2 === true) this.$store.commit('vote/SET_DISABLE_VOTE2' , {disable : false});
					return;
				} else {
					this.$store.commit('vote/SET_VOTE_CREATIVE', { id: this.id });
					return;
				}
			}
		}
	};
</script>
