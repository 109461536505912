<template>
	<div class="p-explore">

		<!-- Intro -->
		<p-explore-intro :key="'into'" v-if="showIntro"/>

		<!-- Dasboard -->
		<p-tab-nav-explore :key="'explore'" v-if="!showIntro"/>
		<transition name="slide-p-horizontal" mode="out-in">
			<p-explore-dive :key="'dive'" v-if="!showIntro"/>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'p-explore',
		computed : {
			showIntro() { return this.$store.state.explore.showIntro; },
		},
		methods: {
		},
	};
</script>
