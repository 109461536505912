const Utils = {

	install(Vue) {

		Vue.prototype.$isEmail = (email) => {

			const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

			if(email) {
				return emailRegex.test(email);
			} else {
				return false;
			}

		};

		Vue.prototype.$formatEmail = (string) => {

			if(string) {
				return string.toLowerCase();
			} else {
				return string;
			}

		};

		Vue.prototype.$firstCharUppercase = (string) => {

			if(string) {
				return string.charAt(0).toUpperCase() + string.substring(1);
			} else {
				return string;
			}

		};

		Vue.prototype.$getQueryParam = (name, url = window.location.href) => {

			name = name.replace(/[\[\]]/g, '\\$&');

			let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
			let results = regex.exec(url);

			if (!results) {
				return null;
			}
			if (!results[2]) {
				return '';
			}

			return decodeURIComponent(results[2].replace(/\+/g, ' '));

		};


		/**
		 * returns the route path as String matching the given level as number 
		 * ready to use with the router.push() Function
		 * 
		 * @param {number} level
		 * @returns {String} path
		 */
		Vue.prototype.$getRouteName = (level) => {
			let name;

			if(level === 0) name = 'ready';
			if(level === 1) name = 'dive';
			if(level === 2) name = 'explore';

			return name;
		};

		/**
		 * Scroll to top of the page
		 */
		Vue.prototype.$scrollPageToTop = () => {
			window.scrollTo(0,0);
		};

	},

};
export default Utils;
