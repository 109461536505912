<template>
	<p
		:class="['p-tagline', {' p-tagline--block': block}, {' p-tagline--margin': margin}]"
		v-html="text"
	>
		<slot />
	</p>
</template>

<script>
	export default {
		name: 'p-tagline',
		props: {
			text: {
				type: String,
				required: false,
			},
			block: {
				type: Boolean,
				required: false
			},
			margin: {
				type: Boolean,
				required: false
			}
		}
	};
</script>
