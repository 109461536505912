<template>
	<div :class="'m-mod-spacer' + sizeClass + bigMarginClass + smallMarginClass + resetMarginClass">
		<slot />
	</div>
</template>

<script>
	export default {
		name: 'm-mod-spacer',
		props: {
			large: {
				type: Boolean,
				required: false,
				default: false,
			},
			bigMargin: {
				type: Boolean,
				required: false,
				default: false,
			},
			smallMargin: {
				type: Boolean,
				required: false,
				default: false,
			},
			resetMargin: {
				type: Boolean,
				required: false,
				default: false,
			},
		},
		computed: {
			sizeClass() { return this.large ? ' m-mod-spacer--large' : ''; },
			bigMarginClass() { return this.bigMargin ? ' m-mod-spacer--big-margin' : ''; },
			smallMarginClass() { return this.smallMargin ? ' m-mod-spacer--small-margin' : ''; },
			resetMarginClass() { return this.resetMargin ? ' m-mod-spacer--reset-margin' : ''; },
		}
	};
</script>
