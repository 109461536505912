<template>
	<div class="p-mobile-menue">
		<div :class="'p-mobile-menue__burger ' + toggleClass" v-on:click="toggleNav()">
			<img class="p-mobile-menue__icon" src="@/assets/images/icons/burger.svg" />
			<span class="p-mobile-menue__item">Get ready</span>
		</div>


		<div :class="'p-mobile-menue__submenue ' + showClass + hideClass">
			<span class="p-mobile-menue__close" v-on:click="hideNav()"><img src="@/assets/images/icons/close.svg"></span>
			<img class="p-mobile-menue__logo" src="@/assets/images/icons/logo-white.svg">

			<ul class="p-mobile-menue__list">
				<li v-on:click="navigateTo(0)" :class="'p-mobile-menue__list-item ' + getNavClass(0)">Get ready</li>
				<li v-on:click="navigateTo(1)" :class="'p-mobile-menue__list-item ' + getNavClass(1)">
					Dive
					<m-fraction :characterTop="currentDive.toString()" :characterBottom="diveCount.toString()" />
				</li>
				<li v-on:click="navigateTo(2)" :class="'p-mobile-menue__list-item ' + getNavClass(2)">Explore</li>
			</ul>
			<div class="p-mobile-menue__ocean">
				<span class="p-mobile-menue__ocean-bottom"></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'p-user-menue',
		data() {
			return {
				isHiddenSubmenue: false,
				isShowSubmenue: true
			};
		},
		computed: {
			activeLevel() { return this.$store.state.app.activeLevel; },
			unlockedLevel() { return this.$store.state.app.unlockedLevel; },
			currentDive() {
				if(this.$store.state.user.currentDive > this.diveCount) {
					return this.diveCount;
				} else if(this.$store.state.user.currentDive < 1) {
					return 1;
				} else {
					return this.$store.state.user.currentDive;
				}
			},
			diveCount() { return this.$store.state.app.diveCount; },
			showClass() {
				return  this.isHiddenSubmenue ? ' p-mobile-menue__submenue--show' : ' ';
			},
			hideClass() {
				return  this.isShowSubmenue ? ' ' : ' p-mobile-menue__submenue--hide';
			},
			toggleClass() {
				return this.$store.state.userMenu.expandUserMenu ? ' p-mobile-menue__burger--toggle' : '';
			},
			userMenueIsOpen() { return this.$store.state.userMenu.expandUserMenu; },
		},
		methods: {
			toggleNav() {
				this.isShowSubmenue = true;
				this.isHiddenSubmenue = !this.isHiddenSubmenue;

				document.body.classList.add('noScroll');
				// disabled Browswer Scroll on open submenue

				this.$store.commit('userMenu/SET_EXPAND_USER_MENU' , {expandUserMenu : !this.$store.state.userMenu.expandUserMenu});
			},
			hideNav() {
				document.body.classList.remove('noScroll');
				this.isShowSubmenue = false;
				this.isHiddenSubmenue = false;

				this.$store.commit('userMenu/SET_EXPAND_USER_MENU' , {expandUserMenu : this.$store.state.userMenu.expandUserMenu});
			},
			async navigateTo(level) {
				if(level <= this.unlockedLevel) {
					await this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : level});

					this.$router.push(this.$getRouteName(level), (err) => { console.error('Navigation error: ', err);});
					this.hideNav();
					return;
				}

				return;
			},
			getNavClass(number) {
				if(this.activeLevel === number) {
					return ' p-mobile-menue__list-item--active';
				}
				else if(number > this.unlockedLevel) {
					return ' p-mobile-menue__list-item--disabled';
				} else {
					return;
				}
			}
		}
	};
</script>
