export default {
	namespaced: true,
	state: {
		showIntro: true,
		activeTab: 1
	},
	mutations: {
		SET_SHOW_INTRO(state, showIntro) {
			state.showIntro = showIntro;
		},
		SET_ACTIVE_TAB(state, activeTab) {
			state.activeTab = activeTab;
		}
	}
};
