<template>
	<div
		class="m-text-input"
	>
		<m-grid hasSlot>
			<div class="column-9">
				<p-headline
					v-if="headline"
					:text="headline" h2 border
				/>
			</div>
		</m-grid>

		<m-grid hasSlot>
			<div class="column-6">
				<p-text
					v-if="copy"
					:text="copy"
				/>
			</div>
		</m-grid>

		<m-grid hasSlot>
			<slot	/>
		</m-grid>

		<m-grid>
			<p-button
				v-if="onClickSecondary"
				:text="buttonSecondary"
				color="secondary"
				:onClick="onClickSecondary"
			/>
			<p-button
				:text="button"
				color="fillup"
				submit
				:disabled="buttonDisabled"
			/>
		</m-grid>
	</div>
</template>

<script>
	export default {
		name: 'm-m-text-input',
		props: {
			headline: {
				type: String,
				required: true,
			},
			copy: {
				type: String,
				required: true,
			},
			button: {
				type: String,
				required: true,
			},
			buttonSecondary: {
				type: String,
				required: false
			},
			onClickSecondary: {
				type: Function,
				required: false
			},
			textarea: {
				type: Boolean,
				required: true,
			},
			placeholder: {
				type: String,
				required: true,
			},
			buttonDisabled: {
				type: Boolean,
				required: true,
			},
		},
	};
</script>
