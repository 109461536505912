export default {
	namespaced: true,
	state: {
		name: null,
		logo: null,
		keynoteUrl: null,
		salutationSingular: 'congstar',
		salutationPlural: 'congstars'
	},
	mutations: {

	},
	actions: {

	},
	getters: {
	},
};
