<template>
	<div class="p-user-menu"
		:class="userMenuClass"
		v-on:click="toggleLabel()"
	>

		<img v-if="showIcon" class="p-user-menu__icon" src="@/assets/images/icons/ICOUser.svg" alt="wave icon">
		<p
			v-if="showUsername"
			class="p-user-menu__user-name"
			:class="usernameClass"
		>
			{{firstName}}
		</p>

		<!-- This will only show up on action part of a dive -->
		<div class="p-user-menu__timer-wrapper" v-if="showTimerContainer" >
			<p-timer />

			<!-- Only show timer label on desktop && action part of a dive -->
			<p class="p-user-menu__timer-label" v-html="$t('userMenu.timerLabel')" v-if="showTimerLabel"></p>
		</div>

	</div>
</template>

<script>
	/**
	 * User Menu Component to show user name and timer
	 *
	 * On mobile Screen you can toggle the user name label by tapping on the user menu element
	 *
	 * How to start timer --> look for the documentation in the timer component
	 *
	 * To toggle user name on mobile from another component update the state in the store like this:
	 * this.$store.commit('userMenu/SET_EXPAND_USER_MENU' , {expandUserMenu : !this.$store.state.userMenu.expandUserMenu}
	 */
	export default {
		name: 'p-user-menu',
		data() {
			return {
				windowWidth: 0,
				mobileMaxSize: 1023
			};
		},
		computed: {
			firstName() { return this.$store.state.user.firstName ? this.$store.state.user.firstName : ''; },
			isDiveActionStep() { return this.$store.state.dive.activeDiveStep === 3; },
			isMobile() { return this.windowWidth <= this.mobileMaxSize; },
			timerIsRunning() { return this.$store.state.userMenu.timerIsRunning; },
			expandUserMenu() { return this.$store.state.userMenu.expandUserMenu; },


			showIcon() {
				// don't show icon if diveStep === Action && mobile
				if(this.isDiveActionStep && this.isMobile) {
					return false;
				}

				return true;
			},
			showUsername() {

				if(this.isDiveActionStep && !this.expandUserMenu) {
					return false;
				}
				// don't show username if diveStep === Action && mobile
				if(this.isDiveActionStep && this.isMobile) {
					return false;
				}

				return true;
			},
			showTimerContainer() {

				// depending on current dive step
				// dive step 3 ==> "Action"
				return this.isDiveActionStep;
			},
			showTimerLabel() {

				// only show on action step of dive
				if(this.isDiveActionStep) {

					// on Desktop
					if(!this.isMobile) {

						// hide label after the timer was startet
						if(this.timerIsRunning) {
							return false;
						}
						return true;
					}

					if(this.isMobile && this.expandUserMenu) {
						return true;
					}
				}

				return false;
			},
			showOnlyTimer() {
				if(this.isMobile) {
					// depending on current dive step
					// dive step 3 ==> "Action"
					return this.isDiveActionStep;
				}

				return false;
			},


			userMenuClass() {
				return {
					'p-user-menu--no-padding': this.isDiveActionStep,
					'p-user-menu--timer-mode': this.isDiveActionStep
				};
			},
			usernameClass() {return  this.expandUserMenu && 'user-name--active'; },
		},
		created() {
			window.addEventListener('resize', this.updateWindowWidth);
			this.updateWindowWidth();
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize);
		},
		mounted() {
			if(this.isMobile) {
				this.$store.commit('userMenu/SET_EXPAND_USER_MENU' , {expandUserMenu : !this.$store.state.userMenu.expandUserMenu});
			}
		},
		methods: {
			async toggleLabel() {

				// only allow toggle via tap on mobile
				if(this.isMobile) {
					await this.$store.commit('userMenu/SET_EXPAND_USER_MENU' , {expandUserMenu : !this.$store.state.userMenu.expandUserMenu});
					return;
				}

				// on Desktop always show the user name
				if(!this.isMobile) {
					await this.$store.commit('userMenu/SET_EXPAND_USER_MENU' , {expandUserMenu : true});
					return;
				}
			},
			updateWindowWidth() {
				this.windowWidth = window.innerWidth;
			}
		}
	};
</script>
