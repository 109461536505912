<template>
	<div class="p-step-display">

		<div class="p-step-display__item">

			<div
				:class="'p-step-display__circle' + getCircleClass(1)"
				v-on:click="() => { clickCircle(1); }"
			>
				<div class="p-step-display__number">1</div>
			</div>

		</div>

		<div class="p-step-display__item">

			<div
				:class="'p-step-display__circle' + getCircleClass(2)"
				v-on:click="() => { clickCircle(2); }"
			>
				<div class="p-step-display__number">2</div>
			</div>

		</div>

		<div class="p-step-display__item">

			<div
				:class="'p-step-display__circle' + getCircleClass(3)"
				v-on:click="() => { clickCircle(3); }"
			>
				<div class="p-step-display__number">3</div>
			</div>

		</div>

		<div class="p-step-display__item">

			<div
				:class="'p-step-display__circle' + getCircleClass(4)"
				v-on:click="() => { clickCircle(4); }"
			>
				<div class="p-step-display__number">4</div>
			</div>

		</div>

	</div>
</template>

<script>
	export default {
		name: 'p-step-display',
		props: {
			activeItem: {
				type: Number,
				required: false,
			},
		},
		computed: {
			step() { return this.$store.state.app.step; },
			isDev() { return process.env.NODE_ENV === 'development'; },
			type() { return process.env.VUE_APP_TYPE; },
		},
		methods: {
			getCircleClass(number) {
				if(this.activeItem === number) {
					return ' p-step-display__circle--active';
				}
				else if(this.activeItem > number) {
					return ' p-step-display__circle--done';
				}
				else {
					return '';
				}
			},
			clickCircle(number) {
				if(this.isDev) {
					this.prepareDevData(number);
					this.$store.commit('app/SET_STEP', { step: number }, { root: true });
				}
			},
			async prepareDevData(number) {

				if(this.step < number) {

					// If step 2 or 3
					if(number === 2 || number === 3) {
						this.devSetStep1();
					}

					// If step 3
					if(number === 3) {

						this.devSetStep2();

						const successLogin = await this.$apiLogin({
							email: 'denkwerk@test.de',
							password: 'Test@123',
						});

						if(successLogin) {

							const customer = this.$store.getters['customer/customer'];
							const location = this.$store.getters['location/location'];

							const success = await this.$createOrUpdateCustomer(
								customer,
								location,
								true,
							);

							if(success && this.$store.state.customer.customerId) {
								await this.$apiGetMarkets(this.$store.state.customer.customerId);
							}
						}

					}

				}

			},
			devSetStep1() {

			},
			devSetStep2() {

			},
		}
	};
</script>
