export default {
	namespaced: true,
	state: {
		expandUserMenu: true,
		isTimerRunning: false,
	},
	mutations: {
		SET_EXPAND_USER_MENU(state, payload) {
			state.expandUserMenu = payload.expandUserMenu;
		},
		SET_IS_TIMER_RUNNING(state, payload) {
			state.isTimerRunning = payload.isTimerRunning;
		},
	},
};