<template>
	<div class="m-award-badge">
		<img class="m-award-badge__icon" :src="require('@/assets/images/icons/explore/' + icon)" />
		<div class="m-award-badge__details">
			<p-headline 
				:text="points"
				h4
			/>
			<p-headline :text="category" h5/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'm-award-badge',
		props: {
			icon: {
				type: String,
				required: false,
				default: 'BadgeGeneral.svg'
			},
			points: {
				type: String,
				required: true
			},
			category: {
				type: String,
				required: true
			}
		}
	};
</script>
