<template>
	<div
		class="m-intro"
	>
		<m-grid hasSlot>
			<div class="column-6">
				<img
					class="resized-img"
					:src="require('@/assets/images/m-intro/' + img)"
				/>
			</div>

			<div class="column-6">
				<div class="flex">
					<div>
						<p-headline
							:text="headline"
							border
							h1
						/>

						<slot></slot>

						<m-grid v-if="button">
							<p-button
								:text="button"
								color="fillup"
								:onClick="handleButton"
							/>
						</m-grid>
					</div>
				</div>
			</div>
		</m-grid>
	</div>
</template>

<script>
	export default {
		name: 'p-dive-intro',
		props: {
			img: {
				type: String,
				required: true
			},
			headline: {
				type: String,
				required: true,
			},
			button: {
				type: String,
				required: false,
			},
			buttonClick: {
				type: Function,
				required: false,
			}
		},
		methods: {
			handleButton() {

				this.buttonClick();
			}
		}
	};
</script>
