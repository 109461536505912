<template>
	<div :class="'m-button-group' + spacedClass">
		<slot />
	</div>
</template>

<script>
	export default {
		name: 'm-button-group',
		props: {
			spaced: {
				type: Boolean,
				deafult: false,
			}
		},
		computed: {
			spacedClass() { return this.spaced ? ' m-button-group--spaced' : ''; },
		},
	};
</script>
