<template>
	<div class="p-side-nav-container">
		<nav class="p-side-nav">
			<ul class="p-side-nav__list">
				<li :class="getNavClass(2)" v-on:click="navigateTo(2)">
					{{ $t('sideNavigation.explore') }}
				</li>
				<li :class="getNavClass(1)" v-on:click="navigateTo(1)">
					{{ $t('sideNavigation.dive') }} <m-fraction :characterTop="currentDive.toString()" :characterBottom="diveCount.toString()" />
				</li>
				<li :class="getNavClass(0)" v-on:click="navigateTo(0)">
					{{ $t('sideNavigation.getReady') }}
				</li>
				<span v-if="isMounted"  :class="'p-side-nav_indicator' + indicatorClass" ref="navIndicator" :style="indicatorStyles"
				></span>
			</ul>
		</nav>
	</div>
</template>

<script>
	export default {
		name: 'p-side-nav',
		data() {
			return {
				indicatorStyles : {
					left: '0px',
					width: '0px'
				},
				isMounted: false
			};
		},
		computed : {
			activeLevel() { return this.$store.state.app.activeLevel; },
			unlockedLevel() { return this.$store.state.app.unlockedLevel; },
			currentDive() {
				if(this.$store.state.user.currentDive > this.diveCount) {
					return this.diveCount;
				} else if(this.$store.state.user.currentDive < 1) {
					return 1;
				} else {
					return this.$store.state.user.currentDive;
				}
			},
			diveCount() { return this.$store.state.app.diveCount; },

			indicatorClass() { return this.isMounted ? ' p-side-nav_indicator--active' : ''; }
		},
		watch:{
			$route(to, from){
				if(to.name === from.name) return;

				let level;
				if(to.name === 'ready') level = 0;
				if(to.name === 'dive') level = 1;
				if(to.name === 'explore') level = 2;

				this.navigateTo(level);
			}
		},
		mounted: function() {
			this.isMounted = true;
			this.moveIndicator();
		},
		methods : {
			async navigateTo(level) {
				if (level > this.unlockedLevel) {
					return;
				}

				if(level === 2) {
					await this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : level});

					this.$router.push(this.$getRouteName(level), (err) => { console.error('Navigation error: ', err);});
					this.moveIndicator();
					return;
				}

				if(level <= this.unlockedLevel) {
					await this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : level});

					this.$router.push(this.$getRouteName(level), (err) => { console.error('Navigation error: ', err);});
					this.moveIndicator();
					return;
				}

				return;
			},

			getNavClass(number) {

				// if(number !== 2) {
				// 	return ' p-side-nav__list-item--locked';
				// } else {
				// 	return ' p-side-nav__list-item--active';
				// }

				if(this.activeLevel === number) {
					return ' p-side-nav__list-item--active';
				}
				else if(number > this.unlockedLevel) {
					return ' p-side-nav__list-item--locked';
				}
				else {
					return ' p-side-nav__list-item';
				}
			},
			moveIndicator() {
				if(!this.isMounted) return;
				// get position and width
				const startPosition =  this.$el.querySelector('.p-side-nav__list-item--active').offsetLeft;
				const width = this.$el.querySelector('.p-side-nav__list-item--active').offsetWidth;

				// set position and width
				this.indicatorStyles.left = startPosition + 'px';
				this.indicatorStyles.width = width + 'px';
			}
		}
	};
</script>
