<template>
	<div
		class="m-text-two-col"
	>
		<m-grid hasSlot>
			<div class="column-9">
				<p-headline
					v-if="headline"
					:text="headline" h2 border
				/>
			</div>
		</m-grid>

		<m-grid hasSlot>
			<div class="column-6">
				<p-text
					v-if="copy"
					:text="copy"
				/>
				<!-- class: m-text-two-col__mask, Circle Mask needed later -->
				<div v-if="img" class="show-on-mobile  m-text-two-col__img-container">
					<img
						:title="headline"
						:alt="headline"
						class="resized-img m-text-two-col__img"
						:src="img"
					/>
				</div>
				<p-text
					v-if="img && copySecond"
					:text="copySecond"
				/>
				<p-button
					v-if="img && button"
					:text="button"
					color="fillup"
					:submit="submit"
				/>
			</div>

			<div class="column-6">
				<div v-if="img" class="hide-on-desktop m-text-two-col__img-container">
					<img
						:title="headline"
						:alt="headline"
						class="resized-img m-text-two-col__img"
						:src="img"
					/>
				</div>
				<p-text
					v-else
					:text="copySecond || copyRight"
				/>
			</div>
		</m-grid>
		<m-grid>
			<p-button
				v-if="!img && button"
				:text="button"
				color="fillup"
				:submit="submit"
			/>
		</m-grid>
	</div>
</template>

<script>
	export default {
		name: 'm-text-two-col',
		props: {
			headline: {
				type: String,
				required: true,
			},
			copy: {
				type: String,
				required: true,
			},
			copySecond: {
				type: String,
				required: true,
			},
			copyRight: {
				type: String,
				required: true,
			},
			button: {
				type: String,
				required: true,
			},
			img: {
				type: String,
				required: true,
			},
			submit: {
				type: Boolean,
				required: true,
			},
		},
	};
</script>
