export default {
	namespaced: true,
	state: {
		voteCorrect: null,
		voteCreative: null,
		disableVote2: true,
		showModal: false
	},
	mutations: {
		SET_VOTE_CORRECT(state, payload) {
			state.voteCorrect = payload.id;
		},
		SET_VOTE_CREATIVE(state, payload) {
			state.voteCreative = payload.id;
		},
		SET_DISABLE_VOTE2(state, payload) {
			state.disableVote2 = payload.disable;
		},
		SET_SHOW_MODAL(state, payload) {
			state.showModal = payload.showModal;
		},
		RESET_VOTES(state) {
			state.voteCorrect = null;
			state.voteCreative = null;
			state.disableVote2 = true;
		}
	},
	getters: {
		creative: (state) => {
			return {
				answerId: state.voteCreative
			};
		},
		correct: (state) => {
			return {
				answerId: state.voteCorrect
			};
		},

	}
};
