export default {
	namespaced: true,
	state: {
		firstName: null,
		lastName: null,
		currentDive: 0, // init: 0
		isAnonymous: null, // init: null
		errorCode: null,
		userFromApi: false,
		language: '',
		stats: {},
	},
	mutations: {
		SET_FIRSTNAME(state, payload) {
			state.firstName = payload.firstName;
		},
		SET_LASTNAME(state, payload) {
			state.lastName = payload.lastName;
		},
		SET_CURRENTDIVE(state, payload) {
			state.currentDive = payload.currentDive;
		},
		SET_ERRORCODE(state, payload) {
			state.errorCode = payload.errorCode;
		},
		SET_IS_ANONYMOUS(state, payload) {
			state.isAnonymous = payload.isAnonymous;
		},
		SET_LANGUAGE(state, payload) {
			state.language = payload.language;
		},
	},
	actions: {
		// TODO:  fetch persistent data from API
		SET_BASIC_USER_DATA({ state }, payload) {
			state.firstName = payload.firstName;
			state.lastName = payload.lastName;
			state.currentDive = payload.currentDive;
			state.isAnonymous = payload.isAnonymous;
			state.errorCode = payload.errorCode;
			state.userFromApi = payload.userFromApi;
			state.language = payload.language;
		},
		SET_USERS_STATS({state}, payload) {
			state.stats = payload.stats;
		},
	},
	getters: {
		user: (state) => {
			return {
				firstName: state.firstName,
				lastName: state.lastName,
				currentDive: state.currentDive,
				isAnonymous: state.isAnonymous,
				language: state.language,
			};
		},
		isFirstDive: (state) => {
			return state.currentDive < 1;
		}
	}
};
