import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '@/plugins/authentication';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('../components/pages/login/login.vue')
	},
	{
		path: '/setup',
		name: 'setup',
		component: () => import('../components/pages/first-launch/p-first-launch.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/design',
		name: 'designSystem',
		component: () => import('../components/pages/p-design-system.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/ready',
		name: 'ready',
		component: () => import('../components/pages/ready/p-ready.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/dive',
		name: 'dive',
		component: () => import('../components/pages/dive/p-dive.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/explore',
		name: 'explore',
		component: () => import('../components/pages/explore/p-explore.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/vote',
		name: 'vote',
		component: () => import('../components/pages/vote/p-vote.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/error',
		name: 'error',
		component: () => import('../components/pages/p-error.vue')
	},
	{
		path: '/*',
		name: '404',
		component: () => import('../components/pages/p-error.vue')
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior () {
		return { x: 0, y: 0 };
	}
});

const url = new URL(window.location.href);

router.beforeEach((to, from, next) => {
	if (to.meta.auth && !Auth.authenticated() && !url.searchParams.get('authToken')) {
		next('/login'); // TODO: redirect to dedicated error-page
	} else {
		next();
	}
});

export default router;
